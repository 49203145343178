@import "../../Assets/Styles/variables.scss";

.forgotWrapper {
    width: 40%;
    margin: 0 auto;
    box-shadow: none;
    border: 0;
    // background: transparent;
    .DigicoalLogo {
        width: 200px;
        margin: auto;
    }
    .forgotTitle {
        margin-top: 20px;
        font-family: $font-Gtsectra;
        text-align: center;
        margin-bottom: 10px;
    }
    .signUp {
        margin-top: 20px;
        text-align: center;
        font-size: 14px;
        a {
            text-decoration: none;
            color: $primaryColor;
        }
    }
    .loginBtnSec {
        margin-top: 20px;
    }
}
@media screen and (max-width: 767px) {
    .forgotWrapper {
        width: 100%;
    }
}
