@import "variables";

h1 {
  font-size: 52px;
}

h2 {
  font-size: 42px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 24px;
  font-weight: 600;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

body {
  background: $bg-color !important;
  font-family: $font-primary !important;

  .container-fluid {
    // padding: 0 32px;
  }
}

input.form-control {
  border-color: rgba(0, 0, 0, 0.25);

  &:focus {
    box-shadow: none !important;
  }
}
/* width */
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar:horizontal {
  height: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a3a3a3;
}
.contentWrapper {
  // padding: 20px 0;
  // padding-top: 70px !important;
  .customDataTbl {
    padding-top: 70px;
  }
}

.selectionWrapper {
  @extend .card;
  margin-top: 80px;
  padding: 40px 32px 14px 40px;

  // padding-top: 70px !important;
  &.pt-4 {
    padding-top: 1.5rem !important;
  }

  .selectionItem {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0;

    li {
      width: calc(100% / 4);

      .selectionItemContent {
        margin: 8px;
        text-align: center;
        @extend .card;
        border: solid 2px #ebf4fb;
        min-width: 300px;
        min-height: 210px;
        margin-bottom: 15px;

        &:hover {
          cursor: pointer;
          background-color: $primaryColor;

          img {
            filter: invert(1) brightness(200);
          }

          label {
            color: #fff;
          }
        }
      }

      label {
        color: $font-color;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        font-family: $font-primary;
        margin-top: 20px;
      }
    }
  }
}

.registerForm {
  text-align: center;
  margin-top: 50px;

  .title {
    margin: 30px 0;
  }

  button {
    @extend .primaryBtn;
    box-shadow: none;
    min-width: 198px;
    padding: 16px 32px;

    svg {
      font-size: 24px;
    }
  }
}

.threedot {
  button {
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    background-color: transparent;
    border: 0;

    &.dropdown-toggle:after {
      content: none;
    }

    &.btn-check:checked + .btn,
    &.btn.active,
    &.btn:hover,
    &.btn.show,
    &.btn:first-child:active,
    &:not(.btn-check) + .btn:active {
      color: #333;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .dropdown-menu {
    padding: 15px 10px 15px 10px;
    border-radius: 2px;
    box-shadow: $box-shadow;
    background-color: #fffffe;
    border: 0;
    margin-top: 15px;

    // transform: translate(12px, 27px) !important;
    &:before {
      content: "";
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 15px solid #fffffe;
      position: absolute;
      top: -15px;
      right: 10px;
    }

    a {
      color: #686868;
      font-size: 16px;
      font-family: $font-primary;
    }
  }
}

.action-popup {
  border-color: transparent;
  border: 0 !important;
  z-index: 10;

  .popover-arrow {
    border-color: transparent;

    &::before,
    &::after {
      border-color: transparent;
    }
  }

  .popover-body {
    padding: 15px 10px 15px 10px;
    border-radius: 4px;
    box-shadow: $box-shadow;
    background-color: #fffffe;
    border: 0;

    .popoverList {
      color: #686868;
      font-size: 16px;
      padding: 0px 14px;
      cursor: pointer;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 10px;
      padding-bottom: 15px;

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    .seperator {
      margin: 10px 0;
    }
  }
}

.creationWrapper {
  .creationCard {
    padding: 32px 89px 40px 90px;
    border-radius: 14px;
    box-shadow: 0 8px 24px 0 rgba(32, 32, 36, 0.06);
    background-color: #fff;

    .formStepperWrapper {
      width: 60%;
      margin: 10px auto 40px;

      ul {
        list-style: none;
        padding: 0;
        display: flex;

        li {
          width: 50%;
          text-align: center;

          &.activeStep {
            position: relative;

            span::before {
              content: "";
              width: 32px;
              height: 32px;
              border: solid 8px $primaryColor;
              border-radius: 100%;
              background-size: 100% 100%;
              display: inline-block;
              background-color: #fff;
              z-index: 9;
              position: relative;
            }

            label {
              font-weight: 600;
              color: #294088;
            }
          }

          &.initialStep {
            position: relative;

            span::before {
              content: "";
              width: 32px;
              height: 32px;
              background-color: #d6d9db;
              border-radius: 100%;
              background-size: 100% 100%;
              display: inline-block;
            }
          }

          &.doneStep {
            position: relative;

            span::before {
              content: "";
              width: 32px;
              height: 32px;
              border-radius: 100%;
              background: url(../Images/check.png) no-repeat;
              background-size: 100% 100%;
              display: inline-block;
            }
          }

          &.coalDispatchStep {
            .stepIcon {
              position: relative;

              span::after {
                content: "";
                width: 92%;
                border-top: solid 1.5px #979dac;
                display: inline-block;
                position: absolute;
                top: 14px;
              }
            }
          }

          &.sampleRelatedStep.activeStep {
            position: relative;

            .stepIcon::after {
              content: "";
              width: 92%;
              border-top: solid 1.5px $primaryColor;
              display: inline-block;
              position: absolute;
              top: 14px;
              transform: translate(-108%, 0px);
            }
          }
        }
      }
    }

    form {
      .form-label {
        font-size: 18px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
      }

      input,
      select {
        border-radius: 6px;
        border: solid 1px rgba(0, 0, 0, 0.25);
        min-height: 44px;
        font-size: 16px;
        // background: #fff;

        &::placeholder {
          color: #dddcd6;
        }
      }
    }

    .btnSec {
      @extend .displayFCproperty;

      // margin-top: 113px;
      .secondaryBtn {
        @extend .secondaryBtn;
      }

      .backBtn {
        @extend .secondaryBtn;
        border: 0 !important;
      }

      .primaryBtn {
        @extend .primaryBtn;
      }
    }
  }
}

.btnSec {
  @extend .displayFCproperty;
  position: relative;
  bottom: -10px;
  margin-top: 40px;

  .primaryBtn.btn-primary,
  .secondaryBtn.btn-primary {
    @extend .displayFCproperty;
    justify-content: center !important;
  }
}

.modal {
  .modalRightSide {
    position: fixed;
    right: 0;
    width: 100%;
    max-width: calc(100% - 60%);
    top: 0;
    bottom: 0;
    border: none;
    margin: 0;

    .modal-content {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 100vh;
    }
  }

  .modalLeftSide {
    position: fixed;
    right: 0;
    width: 100%;
    max-width: calc(100% - 60%);
    top: 0;
    bottom: 0;
    border: none;
    margin: 0;

    .modal-content {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 100vh;
    }
  }

  .modal-header {
    border-bottom: solid 1px #d6d9db;
    padding: 14px 30px 10px 60px;
    font-size: 24px;
    font-weight: 600;

    .btn-close {
      opacity: 1;

      &:focus {
        box-shadow: none;
      }
    }
    & + .modal-body {
      max-height: calc(100vh - 120px);
      overflow: auto;
      -ms-overflow-style: thin;
      scrollbar-width: thin;
      scrollbar-color: #b2bfc9 #fff;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #b2bfc9;
        border-radius: 20px;
      }
    }
  }

  .modal-body {
    padding: 0;

    &.p-0 {
      padding-bottom: 3rem !important;
    }

    .preview {
      padding: 16px 60px 0;

      .previewContent {
        padding: 16px 24px 16px 24px;
        border-radius: 6px;
        border: solid 1px rgba(0, 0, 0, 0.1);
        margin: 24px 0 0 0;

        .head {
          font-size: 16px;
          font-weight: 500;
        }

        label {
          font-size: 18px;
          font-weight: 400;
        }

        .content {
          font-size: 16px;
          font-weight: normal;
          word-break: break-word;
        }
      }

      .previewDasContent {
        .head {
          font-size: 16px;
          font-weight: 500;
        }

        label {
          font-size: 18px;
          font-weight: 400;
        }

        .content {
          font-size: 16px;
          font-weight: normal;
          word-break: break-word;
        }
      }
    }
  }

  .modal-footer {
    padding: 16px 60px;
  }
}

.customDataTbl {
  .contentHeading {
    @extend .displayFCproperty;
    align-items: flex-end;

    .btn {
      @extend .primaryBtn;
      box-shadow: none;
      padding: 12px 28px;
      @extend .displayFCproperty;

      svg {
        font-size: 24px;
      }
    }

    & + div {
      // overflow: inherit;
      // margin: -10px;
    }

    & + .scrollbar-container {
      margin-top: -10px;
    }

    .rightSideContent {
      margin-left: 20px;
      @extend .displayFCproperty;

      .filter {
        button.btn-secondary {
          max-width: 40px;
          min-width: 40px;
          padding: 0;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          background-color: #fff;
          border-color: #d6d9db;

          &:hover,
          &:active,
          &:focus {
            background-color: $primaryColor;
            border-color: $primary-outline-color;

            img {
              width: 20px;
              filter: invert(1) brightness(200);
            }
          }

          img {
            width: 20px;
            filter: invert(1) brightness(13);
          }
        }

        position: relative;

        #filter-collapse {
          position: absolute;
          right: 0;
          min-width: 420px;
          z-index: 1;
          padding: 24px 16px;
          border-radius: 6px;
          box-shadow: 0 8px 24px 0 rgba(32, 32, 36, 0.06);
          background-color: #fffffe;
          margin-top: 15px;

          label {
            font-weight: 500;
          }

          &:before {
            content: "";
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 15px solid #fffffe;
            position: absolute;
            top: -15px;
            right: 10px;
          }

          .btnArea.row {
            .col + .col {
              justify-content: flex-end;
              display: flex;
            }

            button {
              &.secondaryBtn.btn-primary {
                @extend .secondaryBtn.btn-primary;
                border: 0;
                justify-content: center;
                min-width: 100px;
                // &:hover {
                //   box-shadow: none;
                // }
              }

              &.primaryBtn {
                min-width: 120px;
                max-width: 120px;
                display: flex;
                justify-content: center;
              }
            }
          }

          input,
          select {
            border-radius: 6px;
            // border: solid 0.4px #979797 !important;
            // height: 48px;
            margin-bottom: 15px;
            border-color: #979797;
          }

          .multi-select {
            max-width: 388px;
          }
        }
      }
    }
  }

  .rdt_TableHeader {
    background-color: transparent;
  }

  .rdt_Table {
    background-color: transparent;

    // margin: 12px;
    .rdt_TableHead {
      .rdt_TableHeadRow {
        background-color: transparent;
        border: 0;

        .rdt_TableCol {
          .rdt_TableCol_Sortable {
            font-size: 14px;
            font-weight: 300;
            @extend .fontStyle;
            font-family: $font-primary;
            border: 0;
            padding: 6px;
          }

          &:last-child {
            justify-content: end;
            min-width: 120px;
            padding-right: 0;
          }
        }
      }
    }
    .rdt_TableBody::-webkit-scrollbar {
      width: 6px;
    }
    .rdt_TableBody::-webkit-scrollbar-thumb {
      background-color: #b2bfc9;
      border-radius: 20px;
    }
    .rdt_TableBody {
      max-height: calc(100vh - 250px);
      overflow: auto;
      -ms-overflow-style: thin;
      scrollbar-width: thin;
      scrollbar-color: #b2bfc9 #fff;
      .rdt_TableRow {
        border-radius: 6px;
        border: 0;
        border-collapse: separate;
        border-spacing: 0 16px;
        // margin-bottom: 16px;
        // padding: 10px 12px 10px 12px;
        margin-bottom: 4px;
        padding: 0px 10px 0px 10px;
        background-color: #fffffe;
        // box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);

        &:nth-child(even) {
          // border-radius: 16px;
          border-radius: 10px;
        }

        .rdt_TableCell {
          font-family: $font-primary;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.31;
          letter-spacing: normal;
          color: rgba(0, 0, 0, 0.9);

          // &:nth-last-child(2) {
          //   justify-content: center;
          // }
          &:last-child {
            // justify-content: end;
            min-width: 120px;
            padding-right: 0;
          }

          .statusSec {
            border-radius: 6px;
            padding: 5px 12px;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            // min-width: 130px;
            min-width: 50px;
            text-align: center;

            &.draft {
              color: #7a0038;
              background-color: #fde9f2;
            }

            &.submit {
              color: rgba(0, 0, 0, 0.5);
              background-color: #e5e5e4;
            }

            &.approve {
              color: #08440e;
              background-color: #f0fff1;
            }
          }

          .inSevrty {
            background-color: #dc3546;
            color: #ffffff;
            border-radius: 5px;
            text-align: center;
            padding: 4px 10px;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            font-weight: 500;
            font-size: 16px;
            min-width: 100px;
            min-height: 26px;
            &.severityOne {
              background-color: #f8b0b7;
            }
            &.severityTwo {
              background-color: #f6949e;
            }
            &.severityThree {
              background-color: #e87581;
            }
            &.severityFour {
              background-color: #ef5766;
            }
          }

          .feedbackIcon {
            font-size: 32px;
            &.red {
              color: red;
            }
            &.green {
              color: #026902;
            }
          }

          .actionIconsGroup {
            @extend .displayFCproperty;
            position: relative;

            .view {
              color: $primaryColor;
              font-size: 14px;
              cursor: pointer;
              @extend .fontStyle;
              padding-left: 8px;
            }

            .threedot {
              background-color: transparent;
              border: 0;
              color: #000;
            }
          }
        }
      }
    }
  }

  .rdt_Pagination {
    background-color: transparent;
    border: 0;
    min-height: 56px;

    div {
      background: #fff;
    }
  }
}

// calendar icon
.rdt {
  input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    background-image: url("../Images/calendar.svg");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
  }
}

//hour watch icon
.hour {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  background: transparent;
  background-image: url("../Images/watch.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover,
  &:focus {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.progress {
  min-width: 100px;
  border-radius: 4px;
  font-size: 9px;
  line-height: normal;
  background: #e8f9e7;
  --bs-progress-bar-bg: #a1cd3a;
  .progress-bar {
    padding-top: 3px;
    color: #444;
  }
}

// select icon
select {
  &.form-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("../Images/down-arrow.svg");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
  }

  &.select-items {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
  }
}

// search icon
.tblSearch {
  div {
    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: #fff;
      background-image: url("../Images/search.svg");
      background-repeat: no-repeat;
      background-position: left 0.75rem center;
      padding-left: 45px;
      min-height: 40px;
      border-color: #d6d9db;
    }
  }
}

// popup close button
button.btn-close {
  background: transparent url("../Images/close.svg") center/1em auto no-repeat;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  opacity: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

// Responsive scss

@media screen and (min-height: 900px) {
  .customDataTbl .rdt_Table .rdt_TableBody .rdt_TableRow {
    margin-bottom: 11px;
    padding: 10px 12px;

    .rdt_TableCell .statusSec {
      padding: 8px 12px;
    }
  }
}

@media screen and (min-width: 1750px) {
  .customDataTbl .rdt_Table .rdt_TableBody .rdt_TableRow {
    margin-bottom: 8px;

    .rdt_TableCell .statusSec {
      padding: 8px 12px;
    }
  }
}

@media screen and (min-width: 1517px) and (max-width: 1707px) {
  .customDataTbl .rdt_Table .rdt_TableBody .rdt_TableRow {
    min-height: 40px;
    margin-bottom: 6px;

    .rdt_TableCell .statusSec {
      padding: 6px 12px;
    }
  }
}

@media screen and (min-width: 1366px) {
  .contentWrapper {
    // padding: 10px 0;
    .customDataTbl .contentHeading {
      // & + .scrollbar-container {
      //   margin-top: -20px;
      // }
      .rightSideContent {
        .tblSearch div input {
          min-height: 40px;
        }

        .filter button.btn-secondary {
          max-width: 40px;
          min-width: 40px;
          padding: 0;
          height: 40px;

          img {
            width: 18px;
          }
        }
      }
    }
  }
}

// pagination
.pagination-container {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 5px;
  border: solid 1px #e0e0e0;
  background-color: #fff;
  margin: 0 0 0 auto;
  width: fit-content;

  button {
    padding: 6px 4px;
    border-radius: 5px;
    background-color: #e6e6e6;
    display: flex;
    align-items: center;
    border: 0;
    cursor: pointer;

    svg {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .leftpagination {
    margin-right: 20px;
  }

  .rightpagination {
    margin-left: 20px;
  }

  li {
    color: rgba(0, 0, 0, 0.54);
    min-width: 28px;
    text-align: center;
    margin: 0 5px;
    padding: 6px 4px;
    cursor: pointer;

    &.active {
      background: $primaryColor;
      color: #fff;
      border-radius: 4px;
      padding: 3px;
    }
  }
}

.notificationIcon {
  margin: 5px 10px;

  button {
    background-color: transparent;
    color: #000;
    border: 0;
  }

  button.active,
  button:hover,
  button.show,
  button:first-child:active,
  &:not(.btn-check) + button:active {
    color: #000;
    background-color: transparent;
    border-color: transparent;
  }
}

.rootCause {
  .addTxt {
    color: $primaryColor;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;
    width: max-content;

    svg {
      font-size: 20px;
    }
  }

  table {
    border-color: rgba(0, 0, 0, 0.25);
    margin-bottom: 30px;

    th {
      font-weight: 400;
    }
  }
}

textarea {
  border-color: rgba(0, 0, 0, 0.25) !important;
}

.safetyEdit {
  border-bottom: 1px solid #d6d9db;
  margin-bottom: 20px;
  padding-bottom: 10px;

  label {
    font-size: 18px;
    font-weight: 400;
  }
  .content {
    font-size: 16px;
    font-weight: normal;
    word-break: break-word;
  }
}

.remove {
  cursor: pointer;
  text-decoration: underline;
}

.accordion {
  margin-top: 30px;

  .accordion-item {
    border-color: #ebebeb;

    .accordion-header {
      .accordion-button {
        color: $primaryColor;
        background: #f8fbfe;

        &:focus,
        &:active {
          box-shadow: none;
        }
      }
    }
  }

  table {
    margin-bottom: 30px;

    th {
      font-weight: 500;
    }
  }
}

.fromerror {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f44336;
}

.customRadioClass {
  align-items: center;
  display: flex;

  .spaceCont {
    margin-left: 30px;
  }
}

.emptyTabSection {
  margin-top: 45px;
}

.seperatorLine {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

#uploadMultiple {
  display: none;
}

.lti {
  .customCheckbox {
    justify-content: center;
    label {
      font-size: 24px !important;
      &::before {
        width: 27px;
        height: 27px;
      }
    }

    input:checked + label::before {
      width: 27px;
      height: 27px;
    }
  }
}
.noRecordFound {
  padding: 30px 5px;
  text-align: center;
  svg {
    font-size: 20px;
    margin-top: -4px;
    margin-right: 3px;
  }
}
.cusReaflet-map {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #fff;
}
//Leaflet map css
.leaflet-container {
  font-family: $font-primary !important;
}
.leaflet-popup-content-wrapper {
  border-radius: 6px !important;
  .leaflet-popup-content {
    margin: 8px 24px 13px 12px !important;
    min-width: 200px;
    .d-flex {
      margin: 4px 0 !important;
      font-size: 16px;
    }
    strong {
      font-weight: 500 !important;
      padding-right: 5px;
    }
  }
}

.customize-tooltip {
  // max-width: 100%;
  max-width: 500px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.2);
  padding: 0px;

  .popover-body {
    padding: 3px;
    color: #262433;

    .popover-header {
      border: none;
      background: #292075;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.5px;
      overflow: hidden;
    }

    .table {
      td,
      th {
        padding: 7px 15px;
        font-size: 13px;
      }

      th {
        background: #f9f9f9;
        border-bottom-width: 1px;
      }
    }
  }
}

.customize-tooltip.bs-popover-left .arrow::after {
  border-left-color: #ccc;
}

.customize-tooltip.bs-popover-right .arrow::after {
  border-right-color: #ccc;
}

.customize-tooltip.bs-popover-top .arrow::after {
  border-top-color: #ccc;
}

.customize-tooltip.bs-popover-bottom .arrow::after {
  border-bottom-color: #ccc;
}

.cildot,
.thirddot,
.refdot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px; /* Adjust the margin as needed */
}

.cildot {
  background-color: #ffe5ad;
}

.thirddot {
  background-color: #dfccfb;
  margin-left: 20px;
}

.refdot {
  background-color: #d0e7d2;
  margin-left: 20px;
}

.scrollDiv {
  display: flex;
  width: 100%;
  gap: 1%;
  overflow: hidden;
  .cardsDonut {
    flex: 0 0 auto;
    width: 24.2%;
  }
}
.custom-table-header {
  $start1: 17;
  $end1: 26;
  $start2: 27;
  $end2: 37;
  $start3: 38;
  $end3: 50;

  @for $i from $start1 through $end1 {
    div[data-column-id="#{$i}"][data-sort-id="#{$i}"] {
      background-color: #ffe5ad;
      color: black;
    }
  }

  @for $i from $start2 through $end2 {
    div[data-column-id="#{$i}"][data-sort-id="#{$i}"] {
      background-color: #dfccfb;
      color: black;
    }
  }

  @for $i from $start3 through $end3 {
    div[data-column-id="#{$i}"][data-sort-id="#{$i}"] {
      background-color: #d0e7d2;
      color: black;
    }
  }
  .rdt_TableHeadRow > div {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.form-label-input {
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
}

.active-label {
  color: #2c88d1;
}

.otherWthFld {
  display: flex;
  gap: 10px;
}

.scrollTableDashboard > div:first-child {
  height: 22vh;
}
