@import "../../../Assets/Styles/variables.scss";
.customDropdown {
  .reactSelect__input {
    min-height: 28px !important;
  }
  .reactSelect__option {
    color: hsl(0, 0%, 41%);
    border-bottom: 1px solid #d6d9db;
    &:last-child {
      border-bottom: 0;
    }
    &:hover,
    &:active,
    &:focus,
    &:focus-within,
    &:checked {
      // background-color: transparent;
      background: transparent;
      color: $primaryColor;
    }
  }
  .reactSelect__input-container::placeholder {
    color: #dddcd6;
  }
  .reactSelect__indicator-separator {
    background-color: transparent;
  }
  .reactSelect__indicator svg {
    color: #979797;
    width: 25px;
    height: 25px;
  }
}
