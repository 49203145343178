.fuelImgSec {
  max-height: 150px;
  overflow: hidden;
  border-radius: 14px;
  box-shadow: 0px 0 3px 0 rgba(32, 32, 36, 0.6);
  background-color: #fff;
  padding: 0;

  img {
    width: 100%;
    cursor: pointer;
    min-height: 100px;
  }
}

@media screen and (max-width: 575px) {
  .col-sm-6 {
    .fuelImgSec {
      margin-bottom: 20px;
    }

    &:last-child {
      .fuelImgSec {
        margin-bottom: 0;
      }
    }
  }
}

.fuelSensor {
  width: 98%;
  margin-left: 11px;
  padding: 20px 10px 20px 20px !important;
  margin-bottom: 10px;
}