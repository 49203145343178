@import "../../Assets/Styles/variables.scss";

.tabSection {
  width: calc(100% - 44%);
  .misReportList {
    .miscroll.approvalHistoryTabs {
      width: 100%;
      margin: 0px;
      overflow: hidden;
      display: flex;
      ul {
        display: flex;
        align-items: center;
        list-style: none;
        max-width: 100%;
        overflow: hidden;
        padding-left: 0;
        scroll-behavior: smooth;
        margin-bottom: 0px;
        li {
          white-space: nowrap;
          a {
            min-width: fit-content;
            text-decoration: none;
            padding: 0 15px;
            color: rgba(0, 0, 0, 0.5);
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
            padding-bottom: 0px;
            &.active {
              color: $primaryColor;
              border-bottom: 1px solid $primaryColor;
            }
          }
          .tabBtn {
            min-width: fit-content;
            text-decoration: none;
            padding: 0 15px;
            color: rgba(0, 0, 0, 0.5);
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
            padding-bottom: 0px;
            cursor: pointer;
            &.active {
              color: $primaryColor;
              border-bottom: 1px solid $primaryColor;
            }
          }
        }
      }
      .next,
      .prev {
        cursor: pointer;
        background: #fff;
        padding: 6px 4px !important;
        border-radius: 5px;
        background-color: #e6e6e6;
        display: flex;
        align-items: center;
        border: 0;
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  .tabSection {
    width: calc(100% - 40%);
  }
}
@media screen and (max-width: 757px) {
  .tabSection.emptyTabSection {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .tabSection .misReportList .miscroll.approvalHistoryTabs ul li a {
    padding: 0 10px;
    span {
      font-size: 14px;
    }
  }
}

.dashboardTab {
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    width: 100%;
    background: #fff;
    padding: 10px 0;
    border-radius: 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 4px;

    li {
      // width: calc(100% / 6);
      position: relative;
      cursor: pointer;
      text-align: center;
      margin: 0 20px;
      .active {
        position: relative;
        background: #e8f2fa;
        color: #2c88d1;
        min-width: 130px;
        display: inline-block;
        border-radius: 50px;
        padding: 5px 10px;
      }
      .tabActivation {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        display: inline-block;
        margin-left: 10px;
        &.red {
          background-color: rgb(233, 0, 0);
        }
        &.green {
          background-color: rgb(0, 163, 0);
        }
      }
    }
  }
}
