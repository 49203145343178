// variable scss start here
@import "../../Assets/Styles/variables.scss";
// variable scss end here

// header scss start here
.main-header {
    box-shadow: 0 6px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fffffe;
    min-height: 65px;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 999;
    img.mainLogo {
        width: 180px;
        cursor: pointer;
    }
    .user {
        .btn {
            width: 48px;
            height: 48px;
            padding: 10px 16px;
            background-color: $primary-outline-color;
            border-radius: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-family: $font-roboto;
            border: 0;
            .user .userName {
                line-height: normal;
            }
            h4 {
                margin-bottom: 0;
            }
        }
        .permission {
            display: flex;
            flex-wrap: wrap;
            span {
                display: inline-block;
                font-size: 10px;
                color: #1a50a3;
                background: #e3eaf3;
                padding: 4px 10px;
                border-radius: 25px;
                line-height: normal;
                font-weight: 500;
                margin: 1px;
                max-width: fit-content;
            }
        }
    }
}
// header scss end here
.dropdown-menu {
    padding: 0;
    .dropdown-header {
        padding: 10px;
        border-bottom: 1px solid #ddd;
        color: #1a50a3;
        font-size: 16px;
    }
}
@media screen and (max-width: 767px) {
    .main-header {
        img.mainLogo {
            width: 130px;
        }
        .user.dropdown {
            .btn {
                width: 38px;
                height: 38px;
                h4 {
                    font-size: 18px;
                }
            }
        }
    }
}

.notificationIcon {
    .dropdown-menu {
        max-height: 230px;
        overflow: auto;
        .dropdown-header {
            padding: 0;
            .dropdown-item {
                padding: 14px;
                &:hover {
                    border-radius: 5px;
                }
                .notification-details {
                    position: relative;
                    margin-left: 10px;
                    .new {
                        background: $primaryColor;
                        width: 7px;
                        height: 7px;
                        border-radius: 100%;
                        position: absolute;
                        left: -11px;
                        top: 4px;
                    }
                    .mssg {
                        padding-right: 10px;
                        width: 210px;
                        white-space: break-spaces;
                        font-size: 14px;
                        display: inline-block;
                    }
                    .date {
                        color: #adadad;
                        font-size: 10px;
                        width: 20%;
                    }
                }
                &.notRead {
                    background-color: #f1f1f1;
                    .mssg {
                        &.text-note {
                            color: $primaryColor;
                            font-weight: 500;
                        }
                    }
                    .date {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
