.MgmtWrapper {
    background-color: transparent !important;
    box-shadow: none !important;
    margin-top: 0 !important;
    padding: 35px 0 !important;
    .dashboardItem {
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        a {
            text-decoration: none;
            .dashboardItemContent {
                // border: 2px solid rgb(44, 136, 209);
                overflow: hidden;
                box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
                padding: 25px;
                display: flex;
                align-items: center;
                background: #fff;
                .imgArea {
                    width: 70px;
                    height: 70px;
                    background: #2c88d1;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // margin: 0 auto;
                    img {
                        max-width: 40px;
                        filter: brightness(200);
                    }
                }
                label {
                    color: #2c88d1;
                    text-decoration: none;
                    font-size: 20px;
                    margin-left: 10px;
                }
            }
        }
    }
}
#is_lti_permission + label {
    color: #2c88d1;
    font-weight: 600 !important;
}
