.wrapperContainer {
  margin-top: 10px;
}
.firstRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.tabFilter {
  display: flex;
  gap: 4px;
}
.heading {
  font-size: 20px;
  font-weight: 500;
}
/* #zoomSec_2{
    width: 100%;
    margin-top: 20px;
} */

