.fuelMgmtWrapper {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-top: 0 !important;
  padding: 35px 0 !important;
  .dashboardItem {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    a {
      text-decoration: none;
      .dashboardItemContent {
        // border: 2px solid rgb(44, 136, 209);
        overflow: hidden;
        box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06),
          0 1px 4px rgba(0, 0, 0, 0.08);
        padding: 25px;
        display: flex;
        align-items: center;
        background: #fff;
        .imgArea {
          width: 70px;
          height: 70px;
          background: #2c88d1;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          // margin: 0 auto;
          img {
            max-width: 40px;
            filter: brightness(200);
          }
        }
        label {
          color: #2c88d1;
          text-decoration: none;
          font-size: 20px;
          margin-left: 10px;
        }
      }
    }
  }
}
.customHeight {
  max-height: calc(100vh - 160px);
  overflow: auto;
}
.reportCard {
  padding: 40px 40px 40px 40px;
  border-radius: 14px;
  box-shadow: 0 8px 24px 0 rgba(32, 32, 36, 0.06);
  background-color: #fff;
  .uploadFileBtn {
    .uploadLabel {
      background: #2c88d1;
      border-radius: 4px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;
      & + input {
        display: none;
      }
      svg {
        color: #ffffff;
      }
    }
  }
  .contractorForm {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  .reportformCardContent {
    background-color: #fffffe;
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    padding-right: 20px;
    .reportTble {
      thead tr {
        th {
          color: rgba(0, 0, 0, 0.9);
          font-size: 20px;
          font-weight: 500;
          padding: 20px 10px;
          border-bottom: 0;
          width: calc(100% / 3);
          &:first-child {
            background-color: #eef9ff;
            border-radius: 8px;
          }
        }
        &:last-child {
          th {
            border-bottom-left-radius: 8px;
          }
        }
      }

      tbody {
        tr {
          th {
            background-color: #eef9ff;

            border-bottom: 0;
          }
          td {
            font-size: 16px;
            font-weight: 500;
            border-bottom: 0;
          }
        }
      }
    }
  }
  table {
    tr {
      th {
        white-space: nowrap;
        font-size: 16px;
        font-weight: 600;
      }
      td {
        .rdt {
          input {
            padding: 0.375rem 0.25rem;
            font-size: 0.9rem;
            background-position: right 0.245rem center;
          }
        }
      }
    }
  }
  td {
    position: relative;
    .small,
    .fromerror {
      position: absolute;
      font-size: 10px;
      left: 1px;
    }
    .addTxt {
      color: #2c88d1;
      // display: flex;
      align-items: center;
      cursor: pointer;
      text-decoration: underline;
      width: max-content;
    }
  }

  .trError {
    font-size: 12px;
    color: #e64141;
  }
}

.uploadBtn {
  background: #2c88d1;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}



.suggestion-input-container {
  position: relative;
}

.suggestion-list {
  position: absolute;
  top: 100%; /* Position the list below the input */
  left: 0;
  z-index: 1; /* Ensure it overlays other content */
  background-color: #fff; /* Background color for the list */
  // border: 1px solid #ccc; /* Border for the list */
  list-style: none;
  padding: 0;
  margin: 0;
  width: 327px;
  max-height: 200px;
  overflow-y: auto;
}

.suggestion-list li {
  padding: 8px;
  cursor: pointer;
}

.suggestion-list li:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}
