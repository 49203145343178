.sidebarWrapper {
  display: grid;
  //grid-gap: 8px;
  grid-template-columns: 7.6875rem auto;
  grid-template-areas: "sidebar content";
  // grid-template-columns: 7.6875rem auto auto;
  // grid-template-areas: "sidebar content content";

  .mgmtSidebar::-webkit-scrollbar {
    width: 6px;
  }
  .mgmtSidebar::-webkit-scrollbar-thumb {
    background-color: #b2bfc9;
    border-radius: 20px;
  }
  .mgmtSidebar {
    background: #143c5d;
    height: calc(100vh - 60px);
    overflow-x: hidden;
    margin-top: 60px;
    -ms-overflow-style: thin;
    scrollbar-width: thin;
    scrollbar-color: #b2bfc9 #fff;
    .nav-menu {
      &.nav {
        flex-direction: column;
      }
      .nav-items {
        .nav-item {
          display: flex;
          border-bottom: 1px solid #295070;
          min-height: 110px;
          flex-direction: column;
          justify-content: center;
          .nav-link {
            display: grid;
            justify-content: center;
            img {
              filter: brightness(200);
              width: 32px;
            }
            span {
              color: #fff;
              text-align: center;
              font-size: 13px;
              line-height: 15px;
              display: inline-block;
              margin-top: 10px;
            }
          }
          &:hover,
          &.active {
            background: #eee;
            img {
              filter: none;
            }
            span {
              color: #143c5d;
            }
          }
        }
      }
    }
  }
  .tabSection .misReportList .miscroll.approvalHistoryTabs ul li {
    a,
    span.listing {
      color: #2c88d1;
      border-bottom: 2px solid #2c88d1;
      background: #ffffff;
      padding: 8px 20px;
      cursor: pointer;
      display: inline-block;
      &.active {
        color: #ffffff;
        border-bottom: 2px solid #2c88d1;
        background: #2c88d1;
      }
      &.noVisibal {
        background: #e8e0e033;
        color: #c8c7c7;
        border-bottom: 2px solid #d7cfcf33;
        cursor: default;
      }
    }
  }
}
