@import "../../Assets/Styles/variables.scss";

.errorWrapper {
    .errorContent {
        background-image: url(../../Assets/Images/error-bg.png);
        background-position-y: bottom;
        background-size: cover;
        background-size: 100%;
        height: 100vh;
        background-repeat: no-repeat;
        padding: 120px 0;
        text-align: center;
        h1 {
            text-align: center;
            font-size: 8rem;
            margin-bottom: 20px;
            font-family: $font-Gtsectra;
        }
        h3 {
            margin-bottom: 10px;
            font-size: 22px;
            font-family: $font-secondary;
        }
        h6 {
            color: #bbbbbb;
            letter-spacing: 0.9px;
            font-weight: 500;
            font-size: 16px !important;
            margin-bottom: 40px;
            font-family: $font-secondary;
        }
    }
}
