// variables
@import "../Styles/variables.scss";
//Variables end
.mobWrapper {
  .mobileSidebarWrapper {
    margin-top: -20px;
    //background: #ededf5;
    .container-fluid {
      padding: 0 15px;
    }
    .mobileHeader {
      background: $cement-blue-color;
      padding: 10px;
      height: 60px;
      display: flex;
      align-items: center;
      position: fixed;
      left: 0;
      right: 0;
      z-index: 999;
      top: 0px;
      & ~ #page-content-wrapper {
        margin-top: 80px;
      }
      svg {
        color: #fff;
        font-size: 20px;
      }
      .mobileHeaderHeading {
        display: flex;
        align-items: center;
        color: #ffffff;
        svg {
          margin-right: 5px;
        }
      }
      .userLogo .btn {
        width: 38px;
        height: 38px;
        padding: 10px 16px;
        background-color: #fff;
        border-radius: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #18365f;
        // font-family: "Roboto";
        border: 0;
      }
    }
    .mobileSidebar {
      position: fixed;
      background: rgba(0, 0, 0, 0.55);
      height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
      margin-top: 0px;
      z-index: 99999;
      top: 0;
      width: 100%;
      transition: transform 0.3s ease-out;
      .nav-menu {
        height: 100vh;
        .nav-items {
          background: $cement-blue-color;
          width: 60%;
          .mobLogoSec {
            background: #ffffff;
            position: relative;
            padding: 10px;
            img {
              max-width: 160px;
            }
            svg {
              position: absolute;
              right: 5px;
              font-size: 20px;
            }
          }
          .nav-item {
            padding: 5px 0;
            border-bottom: 1px solid $cement-blue-color;
            .nav-link {
              display: flex;
              align-items: center;
              img {
                filter: brightness(200);
                width: 20px;
                margin: 0 !important;
              }
              span {
                color: #fff;
                text-align: center;
                font-size: 13px;
                line-height: 11px;
                display: inline-block;
                margin-left: 5px;
              }
            }
            &:hover,
            &.active {
              background: #eee;
              img {
                filter: none;
              }
              span {
                color: $cement-blue-color;
              }
            }
          }
        }
      }
    }
    .selectionWrapper {
      .selectionItem {
        justify-content: start;
        li {
          width: calc(100% / 2);
          a {
            text-decoration: none;
            .selectionItemContent {
              min-width: min-content;
              display: flex;
              padding: 5px;
              flex-direction: column;
              justify-content: center;
              min-height: 160px;
              margin-bottom: 10px;
              .imgArea {
                min-height: 55px;
                img {
                  max-height: 40px;
                }
              }
              label {
                font-size: 12px;
                line-height: 14px;
                margin-top: 0;
              }
            }
          }
        }
      }
    }
    .dashboardTab {
      ul {
        background: transparent;
        border-bottom: 0;
        padding: 10px;
        justify-content: left;
        li {
          margin: 0;
          .tabBtn {
            margin: 0 5px;
            background: #e6f0f9;
            padding: 8px 10px;
            border-radius: 8px;
            display: inline-block;
            min-width: auto;
            border: 1px solid #bfbfbf;
            &.active {
              svg {
                color: #444;
                path {
                  stroke: #444;
                }
              }
              .tabActivation {
                right: -1px;
                top: -1px;
              }
            }
            &.filled {
              border-color: #6ae16c;
              background: #cbf3cf;
              &.active {
                box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.2);
                border-color: #01cf01;
                background: #a8edac;
              }
            }
            &.notfilled {
              background: #f9e5e7;
              border-color: #fc8082;
              &.active {
                box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.2);
                border-color: #f10004;
                background: #fdd5da;
              }
            }
            svg {
              font-size: 24px;
              color: #444;
              path {
                stroke: #444;
              }
            }
            .tabActivation {
              position: absolute;
              margin-left: 0;
              top: 0px;
              right: 5px;
              width: 10px;
              height: 10px;
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }
      }
    }
    .customHeight {
      max-height: 100%;
      .reportCard {
        padding: 2px;
        margin: 10px;
        background: transparent;
        box-shadow: none;
        .FormWrapper {
          background: #fff;
          padding: 30px;
          border-radius: 14px;
          box-shadow: 0 8px 24px 0 rgba(32, 32, 36, 0.06);
          margin-bottom: 80px;
        }
        .mobileFormWrapper {
          margin-bottom: 80px;
          h3 {
            font-size: 16px;
            font-weight: 500;
            margin: 0 0 10px 0;
          }

          .customSwitchSec {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #ffffff;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
            border-radius: 10px;
            padding: 20px;
            position: relative;
            z-index: 1;
            .switchTitle {
              font-size: 16px;
              font-weight: 500;
            }
          }
          form {
            .addDataformCards {
              background: #fff;
              padding: 20px;
              border-radius: 14px;
              position: relative;
              margin-bottom: 20px;
              label {
                margin: 2px 0;
              }
              .evidenceImg {
                display: block;
                max-height: 50px;
              }
              .actionIconsGroup {
                .icons {
                  position: absolute;
                  top: -8px;
                  right: 0;
                }
              }
              .uploadFileBtn {
                margin-left: 10px;
              }
            }
            .addMoreBtn {
              .addTxt {
                color: #1770c0;
                margin-top: 8px;
              }
            }
          }
          .fieldDropDownIco {
            background-image: url("../Images/mobile-ui/down-arrow.svg");
            background-size: 16px;
            background-position: top 9px right 10px;
            background-repeat: no-repeat;
          }
          .fieldCalenderIco {
            background-image: url("../Images/mobile-ui/calender-icon.svg");
            background-size: 24px;
            background-position: top 10px right 5px;
            background-repeat: no-repeat;
          }
        }
        .btnSec {
          position: fixed;
          bottom: 0;
          margin-top: 0;
          background: #e9ecef;
          width: 100%;
          z-index: 999;
          padding: 10px;
          left: 0;
          &.firstTab {
            justify-content: right;
          }
          .iconBtnForm {
            min-width: inherit;
            padding: 12px;
            height: 50px;
            width: 50px;
            font-size: 25px;
          }
        }
      }
    }
    .customDataTbl {
      margin-top: 20px;
      height: calc(100vh - 80px);
      .contentHeading {
        display: flex;
        justify-content: initial;
        flex-direction: column;
        .tabSection {
          width: 100%;
          order: 1;
        }
      }
      @media screen and (max-width: 375px) {
        .tabSection .misReportList .miscroll.approvalHistoryTabs ul li a {
          padding: 0 3px;
          span {
            font-size: 12px;
          }
        }
      }
      @media screen and (max-width: 575px) {
        .tabSection .misReportList .miscroll.approvalHistoryTabs ul li a {
          span {
            font-size: 12.5px;
          }
        }
      }
    }
    .spaceCont {
      margin-left: 0;
    }
    .form-group {
      &.viewbox {
        margin-bottom: 20px;
      }
    }
    .leafLet {
      table {
        thead {
          tr {
            th {
              white-space: break-spaces;
              &:first-child {
                max-width: 70px;
              }
              &:last-child {
                max-width: 80px;
              }
            }
          }
        }
      }
    }
    .coalGradeScreenTabs {
      ul {
        // padding: 0 0 0 5px;
        li {
          padding: 6px 12px 12px 12px;
        }
      }
      .tabFilter {
        margin-top: 0;
        margin-bottom: 0;
        position: absolute;
        right: 43px;
        top: 4px;
        .btn-group {
          .btn {
            font-size: 14px;
            padding: 2px 5px 5px 5px;
            min-width: 30px;
          }
        }
      }
      .tabsSelectsFilter {
        .mb-3 {
          margin-bottom: 0 !important;
        }
      }
      .dashboardHeader {
        background: transparent !important;
        box-shadow: none !important;
      }
      .dashboardFilter {
        right: 8px !important;
      }
    }

    .safetyEdit .ehsFormWrapper form {
      .addDataformCards {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 15px;
        margin: 10px;
        position: relative;
        .actionIconsGroup {
          position: absolute;
          top: -11px;
          right: -10px;
          background: #fff;
        }
      }
      .addMoreBtn {
        margin: 10px;
        .addTxt {
          color: $primaryColor;
        }
      }
    }
    iframe {
      padding: 10px !important;
    }
    .scannerIcon {
      display: flex;
      align-items: start;
      margin-bottom: 1rem;
      .mb-3 {
        width: 90%;
        margin-bottom: 0 !important;
      }
      .uploadFileBtn {
        padding-top: 8px;
      }
    }
    .iq-card {
      .iq-card-body {
        .iq-sub-card {
          padding: 15px 20px;
          width: 100%;
          display: inline-block;
          margin-bottom: 15px;
          background-color: #fff;
          border-radius: 4px;
          border: 1px solid #dadcdd;
          box-shadow: 0px 0px 25px 0px rgba(45, 65, 95, 0.1);
          text-decoration: none;
          .media {
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
            .iq-card-icon {
              width: 50px;
              height: 50px;
              line-height: inherit;
              font-size: 27px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .media-body {
              h5 {
                color: #202124;
              }
              p {
                color: #202124;
              }
            }
          }
        }
        .iq-bg-danger {
          background: #fae1e3;
          padding: 10px 20px;
          border: 1px solid #c90000;
          border-radius: 5px;
          color: #c90000;
        }
        .iq-bg-primary {
          background: #e5f2ff !important;
          color: $primaryColor !important;
        }
      }
    }
    .rdt_Table {
      background-color: #fff;
      margin-top: 10px;
      .rdt_TableHead {
        border-bottom: 1px solid #eee;
        .rdt_TableCol {
          .rdt_TableCol_Sortable {
            padding-right: 25px;
          }
          div {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
      .rdt_TableBody {
        .rdt_TableRow {
          box-shadow: none;
          margin-bottom: 0;
          border-bottom: 1px solid #eee;
          border-radius: 0;
          button {
            &:hover:not(:disabled),
            &:hover,
            &:focus {
              background-color: transparent !important;
            }
            svg {
              color: $primaryColor;
            }
          }
        }
      }
      .rdt_ExpanderRow {
        padding: 15px;
        margin-bottom: 10px;
        border-bottom: 1px solid #eee;
        .tblCollapesedData {
          margin-bottom: 8px;
          .row {
            display: flex;
            align-items: center;
            strong {
              max-width: fit-content;
            }
            label {
              margin-bottom: 0;
              padding-left: 5px;
            }
          }
          .progressBar {
            .progressBarBg {
              width: 60% !important;
            }
            span {
              font-size: 14px;
            }
          }
          .statusSec {
            display: inline-block;
            padding: 5px 10px;
            border-radius: 5px;
            &.draft {
              color: #7a0038;
              background-color: #fde9f2;
            }

            &.submit {
              color: rgba(0, 0, 0, 0.5);
              background-color: #e5e5e4;
            }

            &.approve {
              color: #08440e;
              background-color: #f0fff1;
            }
          }
          .inSevrty {
            display: inline-block;
            background-color: #dc3546;
            color: #ffffff;
            border-radius: 5px;
            text-align: center;
            padding: 4px 10px;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            font-weight: 500;
            font-size: 16px;
            min-width: 100px;
            &.severityOne {
              background-color: #f8b0b7;
            }
            &.severityTwo {
              background-color: #f6949e;
            }
            &.severityThree {
              background-color: #e87581;
            }
            &.severityFour {
              background-color: #ef5766;
            }
          }
        }
      }
    }

    // Dashboard screens css start
    .dashboardHeader {
      padding: 6px 15px;
      margin: 0 -15px 10px -15px;
      background: #fff;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
      .title {
        font-size: 16px;
        padding-bottom: 4px;
      }
      .dashboardOptions {
        .dashboardOpt {
          gap: 5px;
          border-right: none;
          margin-right: 0;
          padding-right: 10px;
          span {
            width: 32px;
            height: 32px;
            font-size: 16px;
            padding-top: 2px;
            align-items: center;
          }
        }
        .dashboardFilter {
          padding: 6px;
          border-radius: 4px;
          width: 30px;
          height: 30px;
          img {
            width: 16px;
          }
        }
      }
    }
    .dashboardWrapper {
      padding: 5px 5px 0 5px;
      margin: 0 -5px;
      max-height: calc(100vh - 230px);
      overflow-x: hidden;
      .dashboardLeftWidgets {
        .dashCards {
          gap: 10px;
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
        .dashSmallCard {
          padding: 12px 20px 15px 20px;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            color: #958d9e;
            font-weight: 500;
            line-height: 1.3em;
            letter-spacing: 0;
            margin-bottom: 8px;
          }
          h4 {
            font-size: 20px;
            font-weight: 600;
            color: #212529;
          }
        }
      }
      .dashboardRightCharts {
        padding-top: 15px;
      }
      .chartCard {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
        .card-header {
          border: none;
          font-weight: 500;
          font-size: 14px;
        }
        .minePieChart {
          min-height: inherit;
        }
      }
      .chartCardBtmSpace {
        margin-bottom: 15px;
        margin-top: 0;
      }
      .tableCard {
        margin-bottom: 12px;
      }
      .tabSection .misReportList .miscroll.approvalHistoryTabs ul {
        overflow: auto;
        scrollbar-width: none;
      }
      .totalValueTbl {
        .mobileDataTbl {
          border-radius: 0;
        }
        .tblTotalData {
          border: 1px solid #b2bfc9;
          border-top: none;
          padding: 8px 15px 12px 20px;
          justify-content: flex-start;
          border-radius: 0 0 6px 6px;
        }
      }
    }
    .font-12 {
      font-size: 12px !important;
      font-weight: 500;
    }
    .noRecordFound {
      padding: 15px 5px;
      font-size: 14px;
    }
    .dashboardTabs {
      width: 100%;
      margin: 0 0 10px 0;
      padding: 0px;
      ul li {
        font-size: 14px;
      }
      .dashboardFilter {
        width: 32px;
        height: 32px;
        background-size: 16px;
        right: 15px;
        top: 4px;
      }
    }
    .tabSection .misReportList .miscroll.approvalHistoryTabs ul li {
      a,
      span.listing {
        //color: #2c88d1;
        border-bottom: 1px solid #999;
        background: #ffffff;
        padding: 6px 10px;
        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        &.active {
          color: #ffffff;
          border-bottom: 1px solid $primaryColor;
          background: $primaryColor;
        }
        &.noVisibal {
          background: #e8e0e033;
          color: #c8c7c7;
          border-bottom: 1px solid #d7cfcf33;
          cursor: default;
        }
      }
    }
  }
  .font-12 {
    font-size: 12px !important;
    font-weight: 500;
  }
  .noRecordFound {
    padding: 15px 5px;
    font-size: 14px;
  }
  .dashboardTabs {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0px;
    ul li {
      font-size: 14px;
    }
    .dashboardFilter {
      width: 32px;
      height: 32px;
      background-size: 16px;
      right: 15px;
      top: 4px;
    }
  }
  .coalGradeScreenTabs {
    justify-content: space-between;
    flex-direction: column-reverse;
    ul {
      width: 100%;
      li {
        font-size: 15px;
        padding: 12px 15px;
      }
    }
    .tabsSelectsFilter {
      width: 100%;
      padding: 12px 65px 0 15px;
      .tabFilter {
        position: inherit;
        gap: 12px;
        .mb-3 {
          margin-left: 0;
          & > div {
            width: 100%;
          }
          .form-label {
            display: none;
          }
          &:first-child {
            width: 75%;
          }
          &:last-child {
            width: 25%;
          }
        }
      }
    }
    .dashboardHeader {
      padding: 0;
      margin: 0;
      .dashboardOptions .dashboardFilter {
        width: 38px;
        height: 38px;
        right: 15px !important;
        top: 12px;
        img {
          width: 20px;
        }
      }
    }
  }
  .tabSection .misReportList .miscroll.approvalHistoryTabs ul li {
    a,
    span.listing {
      //color: #2c88d1;
      border-bottom: 1px solid #999;
      background: #ffffff;
      padding: 6px 10px;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      &.active {
        color: #ffffff;
        border-bottom: 1px solid $primaryColor;
        background: $primaryColor;
      }
      &.noVisibal {
        background: #e8e0e033;
        color: #c8c7c7;
        border-bottom: 1px solid #d7cfcf33;
        cursor: default;
      }
    }
  }
  .fuelConsumptionCards {
    box-shadow: none;
    border-radius: 0 0 5px 5px !important;
    padding: 12px !important;
    .secondaryFilter {
      margin: -12px -15px 10px -15px;
      // padding: 10px 10px 0px 14px;
      box-shadow: none;
      border-radius: 0 0 5px 5px !important;
      padding: 12px !important;
      .secondaryFilter {
        margin: -12px -15px 10px -15px;
        padding: 10px 10px 0px 14px;
        box-shadow: none;
        .selectKpiDropdown .mb-3 {
          margin-bottom: 10px !important;
        }
        .tab-option {
          margin-bottom: 10px;
          gap: 6px;
          .btn-group {
            &:nth-child(1) {
              .btn {
                background-image: url("../Images/mobile-ui/bymake-icon.svg");
              }
              .btn-check:checked + .btn {
                background-image: url("../Images/mobile-ui/bymake-icon-hv.svg");
              }
            }
            &:nth-child(2) {
              .btn {
                background-image: url("../Images/mobile-ui/bycapacity-icon.svg");
              }
              .btn-check:checked + .btn {
                background-image: url("../Images/mobile-ui/bycapacity-icon-hv.svg");
              }
            }
            &:nth-child(3) {
              .btn {
                background-image: url("../Images/mobile-ui/bymodal-icon.svg");
              }
              .btn-check:checked + .btn {
                background-image: url("../Images/mobile-ui/bymodal-icon-hv.svg");
              }
            }
            &:nth-child(4) {
              .btn {
                background-image: url("../Images/mobile-ui/byeqpt-icon.svg");
              }
              .btn-check:checked + .btn {
                background-image: url("../Images/mobile-ui/byeqpt-icon-hv.svg");
              }
            }

            .btn {
              padding: 0;
              // border: none;
              background-size: 22px;
              width: 36px;
              height: 36px;
              border: 1px solid #212529;
              background-position: center;
              background-repeat: no-repeat;
              box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
              content: "";
              display: inline-block;
              z-index: 9;
              span {
                display: none;
              }
            }
            .btn-check:checked + .btn {
              border-color: $primaryColor;
              span {
                color: #fff;
                border: 1px solid $primaryColor;
                display: inline-block;
                position: absolute;
                top: 125%;
                padding: 2px 8px 4px 8px;
                text-align: left;
                background-color: $primaryColor;
                z-index: 9;
                right: 0px;
                white-space: nowrap;
                border-radius: 4px;
                font-size: 12px;
                letter-spacing: 0.7px;
                &:after {
                  bottom: 100%;
                  right: 10px;
                  border: solid transparent;
                  content: "";
                  height: 0;
                  width: 0;
                  position: absolute;
                  pointer-events: none;
                  border-color: rgba(44, 136, 209, 0);
                  border-bottom-color: #2c88d1;
                  border-width: 6px;
                  margin-left: -6px;
                }
              }
            }
          }
        }
      }
      .col-md-6 {
        &:last-child {
          .chartCardBtmSpace {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  //Mobile data table start
  .shadow-none {
    box-shadow: none !important;
  }
  .mobileDataTbl {
    background-color: #ededf5;
    border: 1px solid #b2bfc9;
    padding: 0px 6px 3px 6px;
    border-radius: 0 0 6px 6px;
    .rdt_Table {
      background-color: #ededf5;
      margin-top: 0;
      .rdt_TableHead {
        border: none;
        .rdt_TableHeadRow {
          padding-left: 0;
          min-height: 30px;
          background: transparent;
          border: none;
          .kpjGUy {
            flex: 0 0 36px;
          }
          .rdt_TableCol {
            padding-right: 10px;
            padding-left: 10px;
          }
          .rdt_TableCol_Sortable {
            padding-right: 10px;
            & > div {
              font-size: 13px;
              font-weight: 500;
            }
          }
        }
      }
      .rdt_TableBody {
        .rdt_TableRow {
          box-shadow: none;
          margin-bottom: 3px;
          margin-top: 3px;
          border: 1px solid #d2dae3bf;
          border-radius: 5px;
          background-color: #fff;
          position: relative;
          .cLPfJ {
            min-width: 36px;
            flex: 0 0 36px;
          }
          button svg {
            color: $primaryColor;
            margin: 0 4px 0 8px;
          }
          .rdt_TableCell {
            padding-left: 10px;
            padding-right: 10px;
            .statusSec {
              position: relative;
              display: flex;
              align-items: center;
              &:before {
                width: 8px;
                height: 8px;
                min-width: 8px;
                min-height: 8px;
                content: "";
                display: inline-block;
                border-radius: 100%;
                margin-right: 8px;
                background: #b2bfc9;
              }
            }
            .draft {
              color: #1061dc;
              &:before {
                background-color: #1061dc;
              }
            }
            .inprogress {
              color: #e3b600;
              &:before {
                background-color: #e3b600;
              }
            }
            .assigned {
              color: #143c5d;
              &:before {
                background-color: #143c5d;
              }
            }
            .completed {
              color: #1c9240;
              &:before {
                background-color: #1c9240;
              }
            }
            .closed {
              color: #d14141;
              &:before {
                background-color: #d14141;
              }
            }
          }
        }
        .rdt_ExpanderRow {
          padding: 5px 12px 10px 12px;
          margin-bottom: 10px;
          margin-top: -5px;
          border-radius: 0px 0px 5px 5px;
          border: 1px solid #d2dae3bf;
          box-shadow: 0px 4px 8px 0px rgba(169, 170, 181, 0.15);
          .tblCollapesedData {
            margin-bottom: 0;
            .row {
              align-items: flex-start;
              padding: 3px 10px !important;
              font-size: 13px;
              strong {
                font-weight: 500;
                padding-left: 4px;
                padding-right: 2px;
              }
              label {
                padding-left: 5px;
                padding-right: 0;
              }
              &.descriptionFull {
                flex-direction: column;
                label {
                  padding: 3px 0 5px 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  body {
    background: #ededf5 !important;
  }
  .customClose.modal-header {
    top: -8px;
    right: -5px;
  }
  .modal-content .safetyModal {
    margin-top: 15px;
  }
  .filterModal .modal-dialog {
    max-width: inherit;
    .modal-content {
      height: calc(100vh - 60px);
      .cndrPosition .rdtPicker {
        margin-left: 0;
      }
    }
  }
  .fuelConsumptionCards .secondaryFilter .selectKpiDropdown .mb-3 {
    & > div {
      width: 100%;
    }
    .form-label {
      display: none;
    }
  }
  .customDropdown {
    .reactSelect__indicator svg {
      width: 16px;
      height: 16px;
    }
    .reactSelect__input-container {
      padding-bottom: 0;
      padding-top: 0;
    }
    .reactSelect__single-value {
      font-size: 13px;
    }
  }
  .fieldCalenderIco {
    background-image: url("../Images/mobile-ui/calender-icon.svg");
    background-size: 24px;
    background-position: top 12px right 5px;
    background-repeat: no-repeat;
  }
  .fieldDropDownIco {
    background-image: url("../Images/mobile-ui/down-arrow.svg");
    background-size: 16px;
    background-position: top 10px right 12px;
    background-repeat: no-repeat;
  }
}

.appBottomMenu {
  min-height: 56px;
  position: fixed;
  z-index: 999;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  // box-shadow: 0 1px 3px 0 rgb(0, 0, 0, /9%);
  padding-left: 4px;
  padding-right: 4px;
  .item {
    width: calc(100% / 3);
    text-decoration: none;
    &.active {
      border-top: 2px solid $cement-blue-color;
      background-color: rgba(20, 60, 93, 0.1);
      .nav-icon {
        svg {
          color: $cement-blue-color;
        }
      }
      strong {
        color: $cement-blue-color;
      }
    }
    .col {
      font-size: 9px;
      text-transform: uppercase;
      color: #202124;
      letter-spacing: 0.3;
      text-align: center;
      width: 100%;
      height: 56px;
      line-height: 1.2em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      .nav-icon {
        height: 30px;
      }
      strong {
        font-weight: 500;
      }
    }
  }
}

.customDatepickerModal {
  .modal-dialog {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .modal-content {
      max-height: 447px;
      border-radius: 20px 20px 0 0;
      overflow: hidden;
    }
  }
  .rdtOpen {
    .form-control {
      display: none;
    }
    .rdtPicker {
      width: 100%;
      box-shadow: none;
      border: none;
      .rdtDays,
      .rdtYears,
      .rdtMonths,
      .rdtTime {
        padding-top: 10px;
        .rdtSwitch {
          font-weight: 600;
          font-size: 16px;
          letter-spacing: 0.5px;
          // color: $dark-grey-color;
        }
        .rdtPrev span,
        .rdtNext span {
          border: 1px solid #e8e8e8;
          width: 30px;
          height: 30px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          font-weight: 400;
          margin: 0 auto;
          padding-bottom: 8px;
        }
        .rdtPrev span {
          padding-right: 3px;
        }
        .rdtNext span {
          padding-left: 3px;
        }
        .dow {
          color: #9196a6;
          font-weight: 600;
          padding-top: 20px;
          text-transform: uppercase;
          font-size: 12px;
          padding-bottom: 10px;
          letter-spacing: 2px;
        }
      }
      td.rdtDay {
        font-weight: 500;
        height: 40px;
        border-radius: 4px;
        font-size: 14px;
      }
      td.rdtDay.rdtToday {
        background-color: #1770c00d;
        color: $primaryColor;
      }
      td.rdtActive {
        position: relative;
        background-color: transparent !important;
        color: #fff !important;
        text-shadow: none;
        font-size: 15px;
        &::after {
          content: "";
          background: $primaryColor;
          display: inline-block;
          width: 38px;
          height: 38px;
          position: absolute;
          left: 0;
          right: 0;
          top: 1px;
          bottom: 0;
          margin: 0 auto;
          border-radius: 100px;
          z-index: -1;
        }
      }
      td.rdtOld,
      .rdtPicker td.rdtNew {
        color: #e4e3e8;
      }
      .rdtTimeToggle {
        font-weight: 600;
        background: #d3dbe121;
        font-size: 14px;
        padding: 4px;
      }
      .rdtYears,
      .rdtMonths {
        .rdtYear,
        .rdtMonth {
          position: relative;
          padding: 5px;
          font-weight: 600;
          &::after {
            content: "";
            border: 1px solid #e8e8e8;
            display: inline-block;
            height: 38px;
            position: absolute;
            left: 4px;
            right: 5px;
            top: 6px;
            bottom: 0;
            margin: 0 auto;
            border-radius: 100px;
            z-index: -1;
          }
        }
        .rdtYear.rdtActive,
        .rdtMonth.rdtActive {
          &::after {
            content: "";
            border: 1px solid $primaryColor;
            width: auto;
          }
        }
      }
      th {
        border: none;
      }
    }
    .rdtMonths,
    .rdtYears {
      .rdtSwitch {
        padding-bottom: 20px;
      }
    }
    .rdtTime {
      .rdtCounters {
        padding-top: 40px;
        .rdtCounter {
          width: 90px;
          padding: 0 5px;
          .rdtCount {
            height: auto;
            font-size: 24px;
            background: #eee;
            border-radius: 6px;
            font-weight: bold;
            padding: 8px 5px;
          }
          .rdtBtn {
            color: #a7abb4;
            font-size: 18px;
          }
        }
      }
    }
    .rdtCounter .rdtBtn:hover,
    .rdtCounter .rdtBtn:focus,
    tr:first-child th:hover,
    tr:first-child th:focus {
      background: transparent !important;
    }
    .rdtCounterSeparator {
      line-height: 30px;
      font-size: 40px;
      margin-top: 48px;
      margin-right: 3px;
      margin-left: 3px;
    }
  }
}
.modalInnerDatePicker {
  .modal-dialog {
    &:after {
      background: rgba(0, 0, 0, 0.5);
      position: fixed;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .modal-content {
      z-index: 99;
    }
  }
}

.singleSelectModal {
  .modal-dialog {
    margin: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    .modal-body {
      overflow: hidden;
      padding: 20px;
      .reactSelect__menu-list {
        max-height: calc(100vh - 260px);
        -ms-overflow-style: none;
        scrollbar-width: none;
        .reactSelect__option {
          font-weight: 500;
        }
      }
      .reactSelect__menu-list::-webkit-scrollbar {
        display: none;
      }
      .reactSelect__control {
        min-height: 44px !important;
        .reactSelect__single-value {
          font-weight: 500;
        }
      }
    }
    .modal-content {
      border-radius: 0;
      height: 100%;
      border: none;
      .modal-header {
        padding: 15px 20px;
        background-color: #f5f5f5;
        border-radius: 0;
        h5 {
          margin: 0;
        }
        .close {
          left: 11px;
          top: 6px;
          height: 36px;
          width: 36px;
          background-color: transparent;
          display: none;
        }
      }
      .modal-footer {
        justify-content: center;
        display: flex;
        gap: 15px;
        padding: 15px;
        margin: 0 !important;
        width: 100%;
        flex-wrap: initial;
        .btn {
          // padding: 16px 24px;
          border-radius: 8px;
          padding: 12px 20px;
          width: 50%;
          margin: 0;
        }
        .backBtn {
          border: $primaryColor solid 1px;
          background-color: #fff;
          color: $primaryColor;
        }
        .primaryBtn {
          background-color: $primaryColor;
          color: #fff;
        }
      }
    }
  }
}
@media screen and (max-height: 580px) {
  .mobWrapper .mobileSidebarWrapper .customHeight .reportCard .btnSec {
    position: relative;
  }
}

.naCheckbox {
  display: flex;
  align-items: center;
  gap: 5px;
}

.labelNa {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
