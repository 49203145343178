@import "../../../Assets/Styles/variables.scss";

.hindranceWrapper {
  .hindranceContent {
    @extend .card;
    padding: 32px 32px 40px;
    .hindranceData {
      padding: 24px 24px 24px 24px;
      border-radius: 6px;
      // box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
    }
    .addmore {
      width: max-content;
    }
    .addTxt {
      cursor: pointer;
      color: $primaryColor;
      font-size: 16px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      svg {
        font-size: 20px;
        margin-right: 4px;
      }
    }
  }
}
.hindranceContentInfo {
  padding-left: 22px;
  label {
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
    font-weight: 300;
  }
}
.HindranceApproveWrapper {
  .hindranceContentWrapper {
    @extend .card;
    padding: 32px;
    .hindranceContent {
      display: flex;
      align-items: start;
      position: relative;
      h4 {
        position: absolute;
        left: -20px;
      }

      .hindranceContentData {
        padding: 32px;
        border-radius: 6px;
        // box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px rgba(0, 0, 0, 0.1);
        // margin-left: 10px;
        margin-bottom: 20px;
        min-width: 100%;
        label {
          color: rgba(0, 0, 0, 0.9);
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
  }
}
.modal .modal-body .hindrancePreview {
  padding: 0px 20px;
}
@media screen and (max-width: 767px) {
  .HindranceApproveWrapper {
    .title {
      flex-wrap: wrap;
    }
  }
  .hindranceWrapper {
    .title {
      flex-wrap: wrap;
    }
    .hindranceContent {
      .btnSec {
        flex-wrap: wrap;
        .btn {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.viewTitle {
  margin-left: 60px;
  font-weight: 500;
}
.reportedBy {
  margin-right: 10px;
  font-weight: 500 !important;
}
.dividerView {
  border: 0.5px solid #d6d9db;
}
.rightend {
  margin-left: auto;
  font-size: 18px !important;
  opacity: 0.6;
}
.userDetails {
  font-weight: 400 !important;
}
