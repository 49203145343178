// variables
@import "../../../../Assets/Styles/variables.scss";
//Variables end

.dashboardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px 0 15px;

  .dashboardOptions {
    display: flex;

    .dashboardOpt {
      display: flex;
      gap: 10px;
      border-right: 2px solid #d5d5d5;
      margin-right: 10px;
      padding-right: 10px;

      a {
        text-decoration: none;
      }

      span {
        width: 35px;
        height: 35px;
        display: flex;
        background: #ffffff;
        color: $primaryColor;
        border-radius: 100%;
        // align-items: center;
        justify-content: center;
        font-size: 18px;
        line-height: 30px;
        border: 1px solid $primaryColor;
        cursor: pointer;

        &.active {
          background: $primaryColor;
          color: #fff;
        }
      }
    }

    .dashboardFilter {
      background: $primaryColor;
      padding: 9px 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 20px;
      }
    }

    .envFilter {
      right: 5px;
      top: 0px;
    }
  }
}

.filterModal {
  .modal-dialog {
    position: fixed;
    right: 0;
    width: 100%;
    max-width: calc(100% - 80%);
    top: 0;
    bottom: 0;
    border: none;
    margin: 0;

    .modal-content {
      // border-top-right-radius: 0;
      // border-bottom-right-radius: 0;
      border-radius: 0;
      height: 100vh;
      border: none;

      .cndrPosition {
        .rdtPicker {
          margin-left: -120px;
        }
      }

      .modal-footer {
        padding: 15px;
      }

      .filterBtn {
        justify-content: center;
        display: flex;
        gap: 15px;
        margin: 0 !important;
        width: 100%;

        button {
          padding: 12px 20px;
          width: 50%;
        }
      }
    }
  }
}

.dashboardWrapper::-webkit-scrollbar {
  width: 6px;
}

.dashboardWrapper::-webkit-scrollbar-thumb {
  background-color: #b2bfc9;
  border-radius: 20px;
}

.dashboardWrapper {
  max-height: calc(100vh - 125px);
  overflow: auto;
  //padding: 5px 20px 0 15px;
  padding: 5px 12px 0 12px;
  -ms-overflow-style: thin;
  scrollbar-width: thin;
  scrollbar-color: #b2bfc9 #fff;

  .dashCards {
    display: flex;
    flex-direction: column;
    gap: 12.7px;
  }

  .chartCard {
    padding: 0;
    border-radius: 5px;
    // margin-top: 20px;
    margin-top: 3px;

    .card-header {
      border-color: #eee;
      background: #b2bfc9;

      .reactSelect__control {
        border-color: rgb(154, 171, 185);
      }
    }

    .pieCartOuter {
      .echarts-for-react {
        top: -10px;
      }
    }

    .minePieChart {
      min-height: 298px;
    }

    .cardTblData {
      thead {
        th {
          font-size: 13px;
          font-weight: 500;
          white-space: nowrap;
        }
      }

      tbody {
        tr {
          td {
            font-size: 13px;

            &.desData {
              max-width: 180px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .chartCardBtmSpace {
    margin-bottom: 20px;
  }

  .tableCard {
    margin-bottom: 20px;
  }

  .dashSmallCard {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06),
      0 1px 4px rgba(0, 0, 0, 0.08);
    border: 0;
    padding: 10px 20px 15px 20px;

    p {
      margin-bottom: 2px;
      font-size: 13px;
      letter-spacing: 0.5px;
    }

    h4 {
      font-weight: 600;
      font-size: 22px;
      margin: 0;

      small {
        font-size: 0.5em;
      }
    }
    .horzText {
      display: flex;
      gap: 10px;
    }
  }
}

.filterZoomSec {
  display: flex;
  align-items: center;
  min-width: 220px;
  gap: 15px;
  justify-content: flex-end;

  svg {
    font-size: 30px;
    color: #143c5d;
    cursor: pointer;
  }
}

.activeZoomScreen {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 1000;
  max-width: inherit !important;
  width: auto !important;
  height: 100%;
  padding: 0 !important;

  .chartCard {
    margin: 0 !important;
    height: 100vh;

    .card-header {
      border-radius: 0;
    }
  }

  .echarts-for-react {
    height: calc(100vh - 88px) !important;
  }
}

.CAAQMS_chart {
  min-height: 365px;
}

.CMPDIL_chart {
  min-height: 345px;
}

.reactSelect__menu-portal {
  z-index: 1000 !important;
}

//.innerDashboards{max-height: calc(100vh - 90px);}
.incidentDashboardTbl {
  -ms-overflow-style: thin;
  scrollbar-width: thin;
  scrollbar-color: #b2bfc9 #fff;
  max-height: 200px;

  .table {
    .fixedBottom {
      td {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        background: #f2f2f1;
        z-index: 4;
        border-top: 1px solid #ddd;
      }
    }
  }
}

.incidentDashboardTbl::-webkit-scrollbar {
  width: 6px;
}

.incidentDashboardTbl::-webkit-scrollbar-thumb {
  background-color: #b2bfc9;
  border-radius: 20px;
}

.dashboardTabs {
  width: calc(100% - 80px);
  border-bottom: 1px solid #c0cfdb;
  margin: -12px 0 12px -10px;
  padding: 0 22px;
  position: relative;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;

    li {
      font-size: 16px;
      line-height: 20px;
      background: transparent;
      // padding: 8px 12px 12px 12px;
      padding: 8px 15px 12px 15px;
      border: none;
      cursor: pointer;
      position: relative;

      &:after {
        content: "";
        background: $primaryColor;
        height: 2px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transition: all 250ms ease 0s;
        transition: all 250ms ease 0s;
        -webkit-transform: scale(0);
        transform: scale(0);
      }

      svg {
        margin-left: 5px;
      }
    }

    .active {
      color: $primaryColor;
      font-weight: 500;

      &:after {
        content: "";
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }

    .scoreCardDashboards {
      position: relative;

      .dashboardSubmenu {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        opacity: 0;
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% top;
        transform-origin: 100% top;
        -webkit-transition: opacity 0.12s linear 0.12s,
          visibility 0.12s linear 0.12s, -webkit-transform 0.12s linear 0.12s;
        transition: opacity 0.12s linear 0.12s, visibility 0.12s linear 0.12s,
          -webkit-transform 0.12s linear 0.12s;
        transition: opacity 0.12s linear 0.12s, visibility 0.12s linear 0.12s,
          transform 0.12s linear 0.12s;
        transition: opacity 0.12s linear 0.12s, visibility 0.12s linear 0.12s,
          transform 0.12s linear 0.12s, -webkit-transform 0.12s linear 0.12s;
        visibility: hidden;
        background: #ffffff;
        position: absolute;
        border: 1px solid rgba(210, 218, 227, 0.75);
        box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
        border-radius: 0px 0px 5px 5px;
        // padding: 10px 20px 20px 20px;
        margin: 13px 0 0 -12px;
        overflow: hidden;
        z-index: 99;

        label {
          display: inline-block;
          width: 100%;
          padding: 12px 20px 16px 20px;
          border-bottom: 1px solid #eee;
          font-weight: 400;
          color: var(--bs-body-color);
          cursor: pointer;

          &:hover,
          &:focus {
            color: $primaryColor;
            font-weight: 500;
          }
        }
      }

      &:hover {
        .dashboardSubmenu {
          opacity: 1;
          -webkit-transform: scaleY(1);
          transform: scaleY(1);
          visibility: visible;

          label {
            &:last-child {
              border: none;
            }
          }
        }

        svg {
          -webkit-transform: rotate(-180deg);
          -moz-transform: rotate(-180deg);
          -ms-transform: rotate(-180deg);
          -o-transform: rotate(-180deg);
          transform: rotate(-180deg);
        }
      }
    }
  }

  .dashboardFilter {
    border-radius: 4px;
    cursor: pointer;
    background: $primaryColor url("../../../../Assets/Images/filter.svg");
    background-repeat: no-repeat;
    width: 38px;
    height: 38px;
    background-position: center;
    background-size: 21px;
    position: absolute;
    right: -55px;
    top: 10px;
  }
}

.pdfExport {
  border-radius: 4px;
  cursor: pointer;
  background-repeat: no-repeat;
  width: 38px;
  height: 38px;
  background-position: center;
  background-size: 21px;
  position: absolute;
  top: 5px;
  right: 40px;

  &.noFltr {
    right: -20px;
  }
}

.tabswithNumber {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .dashboardOpt {
    .active span {
      background: $primaryColor;
      color: #fff;
    }
  }
}

@media (min-width: 1400px) {
  .dashboardWrapper {
    .dashboardLeftWidgets {
      width: 20%;
    }

    .dashboardRightCharts {
      width: 80%;
    }
  }
}

.autoFit {
  // display: flex;
  height: 215px;

  .row {
    flex-wrap: flex;
  }
}

.colindiaCard {
  border-radius: 5px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #fffffe;
  padding: 20px;
}

.contractorPerformanceTopTbl {
  margin-bottom: 15px;
  padding: 5px 20px 6px 20px;
  border: 1px solid #dfe8ee;
  border-radius: 0 0 5px 5px;
  border-top: none;

  .d-flex {
    align-items: flex-start;
    padding-top: 4px;
    padding-bottom: 4px;
    flex-direction: column;

    strong {
      //min-width: 140px;
      font-weight: 400;
      opacity: 0.5;
      font-size: 13px;
      letter-spacing: 0.5px;
    }

    p {
      padding-left: 0;
      margin: 0;
      font-weight: 500;
      letter-spacing: 0.5px;
      font-size: 15px;
    }
  }
}

.breadcrumbWithTitle {
  padding: 0 0 20px 0;
  display: inline-block;
  width: 100%;

  .breadcrumb {
    padding-bottom: 3px;
  }
}

.topFilterSec {
  padding-bottom: 0px;

  .colindiaCard {
    padding: 12px 12px 12px 10px;
    border: 1px solid #dfe8ee;
    background: #fff;
    border-radius: 5px 5px 0 0;

    .mb-3 {
      margin: 0 !important;
      display: flex;
      align-items: center;

      .form-label {
        margin-bottom: 0;
        white-space: nowrap;
        padding-right: 10px;
        padding-left: 5px;
      }

      & > div {
        width: calc(100% - 70px);
      }
    }

    & > div {
      width: 100%;
    }
  }

  .borderRadius5 {
    border-radius: 5px;
  }
}

.avgTblOuter {
  max-height: 200px;
}

.avgTblData {
  margin-bottom: 0 !important;

  thead th,
  tbody tr td,
  tbody tr th {
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
  }

  thead th {
    font-weight: 500;
  }

  tbody tr th {
    text-align: left;
    font-weight: 500;
    vertical-align: middle;
  }

  .percentageValue {
    strong {
      font-weight: 600;
      padding-right: 3px;
    }

    span {
      opacity: 0.7;
    }
  }
}

.chartPullTop {
  .echarts-for-react {
    margin-top: -28px;
  }
}

.hindranceTbl,
.hindranceLrgTbl {
  tr {
    th,
    td {
      text-align: left !important;
      padding: 8px 12px;
      line-height: 18px;
    }
  }
}

.hindranceLrgTbl {
  min-width: 1400px;
}

.webformCardContent,
.hindranceTblSmall {
  -ms-overflow-style: thin;
  scrollbar-width: thin;
  scrollbar-color: #b2bfc9 #fff;

  //max-height: 200px;
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b2bfc9;
    border-radius: 20px;
  }
}

.hindranceTblSmall {
  max-height: 308px;
}

.lefletMapImg {
  height: 300px;
  object-fit: cover;
}

.mbRemove {
  .mb-3 {
    margin-bottom: 0 !important;
  }
}

.tab-option {
  margin-bottom: 1rem;
}

.tab-option,
.alphabetToggle {
  display: flex;
  //padding: 15px 0 8px 0;
  gap: 5px;

  .btn-group {
    .btn {
      border: 1px solid $primaryColor;
      background: #ffffff;
      color: $primaryColor;
      font-size: 14px;
      border-radius: 4px;
      padding: 4px 10px 5px 10px;

      &:hover,
      &:focus {
        border-color: $primaryColor;
        background: $primaryColor;
        color: #fff;
      }
    }

    .btn-check:checked + .btn {
      border: 1px solid $primaryColor;
      background: $primaryColor;
      color: #fff;
      z-index: 0;
    }
  }
}

.alphabetToggle {
  flex-direction: column;
  gap: 0;
  padding: 0;

  .btn-group .btn {
    border: none;
    color: var(--bs-body-color);
    padding: 6px 20px 8px 20px;
    font-size: 16px;
    border-bottom: 1px solid #b2bfc970;
    border-radius: 0;
  }
}

.fuelConsumptionDays {
  display: flex;
  gap: 8px;
  justify-content: flex-end;

  .dropdown-toggle {
    background-color: $primaryColor !important;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin: -2px 0;

    svg {
      font-size: 22px;
      margin-top: -2px;
      color: #fff;
    }
  }

  .dropdown-menu {
    overflow: hidden !important;
    border: 1px solid #b2bfc94f !important;
    border-radius: 6px !important;
  }
}

.fuelManagementSearch {
  padding: 12px 12px 12px 14px !important;
  border-radius: 5px !important;

  .mb-3 > div {
    width: 100% !important;
  }
}

.uomOption {
  display: flex;
  gap: 8px;
  align-items: center;

  .radioButton {
    position: relative;

    input {
      position: absolute;
      min-height: inherit;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    label {
      border-radius: 4px;
      border: $primaryColor solid 1px;
      padding: 6px;
      background-color: #fff;
      color: $primaryColor;
      min-width: 50px;
      text-align: center;
    }

    input:checked + label {
      background-color: $primaryColor;
      color: #fff;
      font-weight: 500;
    }
  }
}

.fuelConsumptionCards {
  border: 1px solid #dfe8ee !important;
  background: #fff !important;
  padding: 20px 20px 0px 20px !important;
  border-radius: 5px !important;
  margin: 0px 0 15px 0;

  .secondaryFilter {
    margin: -20px -20px 15px -20px;
    border-bottom: 1px solid #dfe8ee;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);
    padding: 16px 20px 0px 20px;

    .selectKpiDropdown {
      .mb-3 {
        display: flex;
        gap: 12px;
        align-items: center;

        .form-label {
          margin: 0;
        }

        & > div {
          width: calc(100% - 40px);
        }
      }
    }
  }
}

.selectEqptCard {
  margin-bottom: 30px;
  background: #b2bfc9;
  padding: 10px;
  border-radius: 5px;

  .mb-3 {
    margin: 0 !important;

    .reactSelect__control {
      min-height: 58px;
    }
  }
}

.coalGradeScreenTabs {
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .paddingTop {
    padding-top: 20px;
  }

  .dashboardHeader {
    .title,
    .dashboardOpt {
      display: none;
    }

    .dashboardFilter {
      top: 3px;
      padding: 6px 8px;
      width: 36px;
      height: 36px;
    }
  }

  .tabFilter {
    display: flex;
    gap: 5px;
    margin-top: 3px;
    margin-bottom: 4px;

    .btn-group {
      .btn {
        border: 1px solid $primaryColor;
        background: #ffffff;
        color: $primaryColor;
        font-size: 16px;
        border-radius: 4px;
        padding: 4px 10px 5px 10px;

        &:hover,
        &:focus {
          border-color: $primaryColor;
          background: $primaryColor;
          color: #fff;
        }
      }

      .btn-check:checked + .btn {
        border: 1px solid $primaryColor;
        background: $primaryColor;
        color: #fff;
        z-index: 0;
      }
    }
  }

  .tabsSelectsFilter {
    .mb-3 {
      margin-bottom: 0 !important;
      margin-left: 15px;
      display: flex;
      align-items: center;
      gap: 6px;

      &:first-child {
        & > div {
          min-width: 180px;
        }
      }

      .form-label {
        margin-bottom: 0;
        font-size: 14px;
      }

      .customDropdown {
        .reactSelect__single-value {
          font-size: 14px;
        }

        .reactSelect__input-container {
          margin: 0;
          padding: 0;
        }

        .reactSelect__indicator svg {
          width: 18px;
          height: 18px;
        }

        .reactSelect__menu-list {
          padding-bottom: 0px;
          padding-top: 0px;

          .reactSelect__option {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.gradeSlippageSec {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  .mb-3 {
    min-width: 170px;
  }
}

.labWisComparisonTab {
  display: flex;
  align-items: center;
  flex-direction: initial;
  justify-content: center;
  gap: 10px;
  margin-top: -5px;

  .btn-group {
    .btn {
      border: 1px solid $primaryColor;
      background: #ffffff;
      color: $primaryColor;
      font-size: 16px;
      border-radius: 4px;
      padding: 4px 10px 5px 10px;
    }
  }
}

.tblTotalData {
  display: flex;
  border-top: 1px solid #ddd;
  padding: 10px 15px 14px 15px;
  justify-content: flex-end;
  font-size: 14px;

  label {
    padding-right: 6px;
  }

  strong {
    font-weight: 600;
    color: #143c5d;
  }
}

.customDropdown.distributionfltr {
  width: 400px;
}

.top-card {
  display: flex;
  justify-content: center;
  width: 28%;
  margin-bottom: 11px;
  padding: 8px 20px 0px 20px !important;
}
.top-card-sap {
  display: flex;
  justify-content: center;
  margin-bottom: 11px;
  padding: 8px 20px 0px 20px !important;
}
.smallTabBtn {
  margin-top: 10px;
  .btn-group {
    margin-right: 5px;
  }
}
.mobSmallTabBtn {
  margin: 0 auto;
  .btn-group {
    margin-right: 5px;
  }
}
.mobBgClrTxt {
  margin-bottom: 5px;
  padding-top: 5px;
  border-radius: 10px;
  background-color: #eef9ff;
}

.scrollTableDashboard > div:first-child {
  height: 22vh;
}
