$font-primary: "Graphik-Trial", sans-serif;
$font-secondary: "Helvetica", sans-serif;
$font-roboto: "Roboto";
$font-graphik: "Graphik";
$font-Gtsectra: "Gtsectra";
$bg-color: #f8fbfd;
$font-color: rgba(0, 0, 0, 0.9);
$primaryColor: #2c88d1;
$primary-outline-color: #1a50a3;
$cement-blue-color: #143c5d;
$box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);
$danderTxt-color: #ef4c56;

.overflow-inherit {
  overflow: inherit;
}
.displayFCproperty {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontStyle {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.primaryBtn.btn-primary {
  padding: 16px 24px;
  border-radius: 8px;
  // box-shadow: 0 2px 6px 1px rgba(44, 136, 209, 0.27);
  background-color: $primaryColor;
  min-width: 146px;
  // max-height: 48px;
  color: #fff;
  @extend .fontStyle;
  // @extend .displayFCproperty;
  &:hover {
    box-shadow: 0 2px 6px 1px rgba(44, 136, 209, 0.27);
    background-color: $primary-outline-color;
  }
}
.secondaryBtn.btn-primary {
  border-radius: 8px;
  border: $primaryColor solid 1px;
  padding: 16px 24px;
  background-color: #fff;
  min-width: 146px;
  color: $primaryColor;
  // max-height: 48px;
  @extend .fontStyle;
  &:hover,
  &:active,
  &:focus {
    background-color: #fff !important;
    color: $primaryColor;
    box-shadow: 0 2px 6px 1px rgba(44, 136, 209, 0.27);
  }
}
.rejectBtn {
  border-radius: 8px;
  border: solid 1px $danderTxt-color;
  color: $danderTxt-color;
}
.font18 {
  font-family: $font-primary;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgba(31, 33, 37, 0.87);
}
.backBtn.btn-primary {
  @extend .secondaryBtn;
  border: 0;
}
.blueLabel {
  padding: 4px 12px 4px;
  border-radius: 11px;
  background-color: #eef9ff;
  color: #294088;
  font-family: $font-graphik;
  font-size: 14px;
}
input,
select {
  border-radius: 6px;
  border: solid 1px rgba(0, 0, 0, 0.25);
  min-height: 44px;
  font-size: 16px;
  &::placeholder {
    color: #dddcd6;
  }
}

.seperator {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 30px 0;
}

.card {
  padding: 32px 32px 43px 40px;
  border-radius: 16px;
  box-shadow: $box-shadow;
  background-color: #fffffe;
}

.radio.radio-primary {
  display: flex;
  align-items: center;
  input {
    width: 20px;
    height: 20px;
    accent-color: $primaryColor;
    margin-right: 12px;
  }
}
.dropdown {
  button.dropdown-toggle:after {
    content: none;
  }
  .dropdown-menu {
    // padding: 5px 10px 5px 10px;
    padding: 0;
    border-radius: 2px;
    box-shadow: $box-shadow;
    background-color: #fffffe;
    border: 0;
    a {
      color: #686868;
      font-size: 16px;
      font-family: $font-primary;
      &:hover {
        background: transparent;
        color: $primary-outline-color;
      }
    }
  }
  .dropdown-item {
    padding: 7px 15px 0px 15px;
    &:last-child {
      padding-bottom: 10px;
    }
  }
}
.title {
  font-family: $font-primary;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $font-color;
  margin-bottom: 24px;
}

@media screen and (max-width: 767px) {
  .title {
    font-size: 20px;
  }
  .secondaryBtn.btn-primary,
  .btn-primary.backBtn,
  .primaryBtn.btn-primary {
    min-width: 106px;
    padding: 12px 20px;
  }
}

.title {
  &.titleWtoutTab {
    position: absolute;
    margin-bottom: 0 !important;
    top: 27px;
  }
}
.backBtnWithSave {
  border-radius: 8px;
  border: $primaryColor solid 1px;
  padding: 16px 24px;
  background-color: #fff;
  min-width: 146px;
  color: $primaryColor;
  margin-right: 10px;
}
