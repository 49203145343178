.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: #284d83 !important;
}
.color {
  color: #284d83 !important;
}
.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #284d83 !important;
}

.nav {
  --bs-nav-link-color: #284d83 !important;
}

// .pdf-full-page {
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
// }

.pdf-buttons {
  flex: 0 0 auto;
  padding: 1rem;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
}

.pdf-container {
  flex: 1 1 auto;
  overflow: auto;

  // height: auto;
}
