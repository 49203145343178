@import "../../Assets/Styles/variables.scss";

.card {
  border: 0 !important;
  border-radius: 10px;
  margin-top: 40px;
  .userDetail {
    width: 80px;
    height: 80px;
    border: 1px solid #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06),
      0 1px 4px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    p {
      color: #fff;
      font-size: 50px;
      line-height: normal;
    }
  }
  .userInfo {
    width: 50%;
    margin: 100px auto 30px;
  }
}
.mobWrapper {
  .userInfo {
    margin: 15px;
    padding: 25px 20px;
  }
}

.changeWrapper {
  width: 40%;
  margin: 5pc auto;
  // margin-top: 10pc;
  box-shadow: none;
  border: 0;
  // background: transparent;
  .DigicoalLogo {
    width: 200px;
    margin: auto;
  }
  .changeTitle {
    margin-top: 20px;
    font-family: $font-Gtsectra;
    text-align: center;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 767px) {
  .changeWrapper {
    width: 100%;
  }
}
