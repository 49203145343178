@import "../../Assets/Styles/variables.scss";

.rightSideContent {

  // .primaryBtn.btn.btn-primary {
  //   max-height: 40px;
  // }
  .approvalBtn {
    padding: 8px 10px !important;
    margin-right: 5px;
    border: 1px solid $primaryColor;
    border-radius: 5px;
    background-color: $primaryColor;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      font-size: 12px;
      white-space: nowrap;
    }

    &.approvedBtn {
      opacity: 0.5;
    }
  }
}

span.btn-icon {
  padding: 7px 7px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;

  &.excel {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    margin-left: 10px;
  }

  &.pdf {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  svg {
    font-size: 24px;
  }
}

@media screen and (max-width: 767px) {
  .customDataTbl .contentHeading {
    .mobSearch {
      width: 100%;
      margin-bottom: 10px;

      .tblSearch {
        .me-3 {
          margin-right: 0 !important;
        }
      }
    }

    .rightSideContent {
      justify-content: flex-end !important;
      width: 100%;

      .filter {
        button.btn-secondary {
          margin-right: 0 !important;
        }

        #filter-collapse {
          transform: translate(-90%, 0%);
        }
      }
    }
  }

  span.btn-icon {
    padding: 3px 4px;

    svg {
      font-size: 20px;
    }
  }
}

.dots {
  cursor: text !important;
}

.disableExcelDownload {
  pointer-events: none;
  opacity: 0.5;
  /* or any other styling to indicate it's disabled */
}

.primary-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  /* Primary button color, change as needed */
  color: #fff;
  /* Text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
}

.primary-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.primary-button:hover {
  background-color: #0056b3;
  /* Change color on hover */
}