@import "../../../Assets/Styles/variables.scss";

// contractual card scss
.webformWrapper {
    .webformCard {
        padding: 32px 32px 40px;
        border-radius: 14px;
        box-shadow: 0 8px 24px 0 rgba(32, 32, 36, 0.06);
        background-color: #fff;
        .contractorForm {
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            padding: 20px;
            margin: 0px 0 30px;
            label {
                font-size: 14px;
                font-weight: 300;
            }
        }
        .webformCardContent {
            border-radius: 6px;
            box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);
            background-color: #fffffe;
            margin-top: 20px;
            padding-right: 20px;
            table {
                margin: 0;
                width: 100%;
                thead {
                    th {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.9);
                        font-weight: 300;
                        height: 60px;
                        vertical-align: bottom;
                        &:first-child {
                            background-color: #eef9ff;
                            border-top-left-radius: 8px;
                        }
                    }
                }
                tbody {
                    tr {
                        th {
                            background-color: #eef9ff;
                            width: 20%;
                            vertical-align: middle;
                            padding-left: 20px;
                            font-size: 16px;
                            font-weight: 500;
                        }
                        td {
                            width: 20%;
                            .mb-3 {
                                margin-bottom: 0 !important;
                                position: relative;
                            }
                            .small.text-danger {
                                position: absolute;
                                bottom: -15px;
                                font-size: 10px;
                            }
                            &:last-child {
                                margin-right: 10px;
                            }
                        }
                        &:last-child {
                            .mb-3 {
                                margin-bottom: 24px !important;
                            }
                            th {
                                border-bottom-left-radius: 8px;
                                vertical-align: baseline;
                            }
                        }
                    }
                }
                .form-control {
                    &:hover,
                    &:focus {
                        border-color: #294088 !important;
                        box-shadow: none !important;
                        background-color: #f8fbfd !important;
                    }
                }
            }
        }
    }
}
.modal-title {
    font-size: 24px;
}
.webformViewContent {
    margin: 30px 0;
    h3 {
        margin-bottom: 20px;
    }
    .webformCardContent {
        border-radius: 6px;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);
        background-color: #fffffe;
        // margin-bottom: 40px;
        table {
            margin: 0;
            width: 100%;
            thead {
                th {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.9);
                    font-weight: 300;
                    height: 60px;
                    vertical-align: bottom;
                    text-align: center;
                    &:first-child {
                        background-color: #eef9ff;
                        border-top-left-radius: 8px;
                    }
                }
            }
            tbody {
                tr {
                    th {
                        background-color: #eef9ff;
                        width: 20%;
                        vertical-align: middle;
                        padding-left: 20px;
                        font-size: 16px;
                        font-weight: 500;
                    }
                    td {
                        width: 20%;
                        text-align: center;
                        .mb-3 {
                            margin-bottom: 0 !important;
                            position: relative;
                        }
                        .small.text-danger {
                            position: absolute;
                            bottom: -15px;
                            font-size: 10px;
                        }
                        &:last-child {
                            margin-right: 10px;
                        }
                    }
                    &:last-child {
                        .mb-3 {
                            margin-bottom: 24px !important;
                        }
                        th {
                            border-bottom-left-radius: 8px;
                            vertical-align: baseline;
                        }
                    }
                }
            }
            .form-control {
                &:hover,
                &:focus {
                    border-color: #294088 !important;
                    box-shadow: none !important;
                    background-color: #f8fbfd !important;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .webformWrapper {
        .title {
            flex-wrap: wrap;
        }
        .webformCard {
            background: transparent;
            padding: 0;
            box-shadow: none;
            h4.font18 {
                margin-top: 20px;
            }
            .webformCardContent table {
                thead th {
                    font-size: 12px;
                    padding: 2px;
                }
                tbody tr {
                    td {
                        padding: 2px;
                        .form-control {
                            padding: 0.375rem;
                        }
                    }
                }
            }
        }
    }
}

.dashboard-tabel-head{
    color: white;
    background: grey;
    padding: 10px 25px;
}