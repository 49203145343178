@import "../../../Assets/Styles/variables.scss";

.docUpload {
  .uploadFileBtn {
    border: 1px dashed $primaryColor;
    padding: 32px;
    border-radius: 6px;
    text-align: center;
    position: relative;
    label {
      cursor: pointer;
      svg {
        path {
          stroke: $primaryColor;
        }
        font-size: 40px;
      }
      h6 {
        color: #979dac;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 10px;
      }
      .browse {
        color: $primaryColor;
        text-decoration: underline;
      }
      p {
        margin-bottom: 0;
      }
    }
    .uploadFile {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  .uploadFileSec {
    margin: 10px 0;
    .form-label {
      font-size: 18px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.9);
    }
    .uploadFileValue {
      padding: 12px 24px;
      border-radius: 16px;
      border: solid 1px rgba(0, 0, 0, 0.1);
      background-color: #fffffe;
      max-width: 50%;
      @extend .displayFCproperty;
      .deleteIcon {
        cursor: pointer;
      }
    }
    .errorBox {
      padding: 16px 24px;
      border-radius: 6px;
      background-color: #ffefef;
      color: #780000;
      width: fit-content;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .uploadFileValue {
    max-width: 100% !important;
  }
}
