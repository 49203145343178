.breadcrumb {
  // position: absolute;
  margin-bottom: 0 !important;

  .customBreadCrumb {
    display: flex;
    align-items: center;
    // margin-left: 30px;

    a {
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      padding-right: 10px;
      position: relative;
      color: #222;
      cursor: text;
      &:after {
        content: "/";
        background: transparent;
        position: absolute;
        top: -2px;
        right: 3px;
        font-size: 16px;
      }
    }
    .clickableLink {
      cursor: pointer;
      &:hover {
        color: #2c88d1;
      }
    }

    .currentOption {
      font-size: 13px;
      font-weight: 400;
      position: relative;
      display: flex;
      align-items: center;
    }
  }
  & + .contentHeading {
    padding-top: 10px;
  }
}
.breadcrumb {
  &.breadcrumbWdoutTabs {
    position: absolute;
    margin-bottom: 0 !important;
    top: 15px;
  }
}
