.span-link {
  color: #2bb0e5 !important;
  cursor: pointer;
}
.badge {
  padding: 0.3em 0.6em;
  font-weight: 600;
  line-height: 1.3;
  text-transform: capitalize;
}
.bg-danger,
.badge-danger {
  background-color: #e64141 !important;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.text-danger {
  color: #e64141 !important;
}
.fieldDataSec {
  text-align: start;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.error_box {
  color: red;
  min-height: 56px;
  margin: 14px 223px 104px 0;
  padding: 16px 24px;
  border-radius: 6px;
  background-color: #ffefef;
  width: fit-content;
  cursor: pointer;
  word-break: break-word;
}
.erroricon {
  font-size: 25px;
}
.error-content {
  text-align: left;
}
