@import "../../../Assets/Styles/variables.scss";

.customCheckbox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  input {
    display: none;
  }
  label {
    cursor: pointer;
    padding-left: 30px;
    margin-bottom: 10px;
    position: relative;
    font-size: 16px !important;
    font-weight: 400 !important;
    &:before {
      content: "";
      border-radius: 4px;
      border: solid 1px #979797;
      width: 20px;
      height: 20px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 2px;
    }
  }

  input:checked + label {
    color: $primaryColor;
    &:before {
      content: "";
      border-radius: 4px;
      border: solid 1px $primaryColor;
      width: 20px;
      height: 20px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 2px;
      background: url(../../../Assets/Images/check.svg) no-repeat;
      background-position: center;
      background-color: $primaryColor;
    }
  }
}
