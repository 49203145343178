@import "../../../Assets/Styles/variables.scss";

.sidebar {
  grid-area: sidebar;
}

.content {
  grid-area: content;
  overflow: hidden;
  //padding-left: 8px;
}

.contentWrapper {
  min-height: 100vh;
  overflow: auto;
  // padding-top: 60px !important;
}
.contentArea {
  iframe {
    width: 100%;
    height: 84vh;
  }
}

.wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 15.6875rem auto auto;
  grid-template-areas: "sidebar content content";

  #sidebar-wrapper {
    .sidebar-heading {
      padding: 0.875rem 1.25rem;
      font-size: 1.2rem;
    }
  }

  .sidebar {
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    top: 0;

    .divider {
      border: solid 0.5px #eef9ff;
    }

    .nav-menu {
      background-color: #143c5d;
      width: 250px;
      height: 100%;
      display: flex;
      flex-direction: column;
      top: 0;
      z-index: 1;
      left: 0;
      transition: 350ms;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      position: fixed;
      margin-top: 4rem;

      .nav-items {
        padding: 16px 16px 60px 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        gap: 7px;
        overflow: auto;

        .nav-item {
          display: flex;
          justify-content: start;
          align-items: center;
          width: 100%;
          height: unset;
          list-style: none;

          a {
            text-decoration: none;
            color: #f5f5f5;
            font-size: 14px;
            height: 100%;
            display: flex;
            align-items: start;
            border-radius: 4px;
            width: 100%;
            gap: 8px;
            padding: 8px 14px;

            img {
              filter: invert(1) brightness(200);
              width: 22px;
              padding-top: 3px;
            }

            span {
              font-size: 14px;
              margin: unset;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
            }
          }

          &.active {
            background-color: $primaryColor;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
.sidebarWrapper {
  .contentArea {
    //margin-top: 70px;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    .customDataTbl {
      height: calc(100vh - 90px);
    }
    .creationWrapper {
      width: 95%;
      margin: 0 auto;
      .customHeight {
        max-height: calc(100vh - 160px);
        .reportCard {
          padding: 40px 40px 40px 40px;
          border-radius: 14px;
          box-shadow: 0 8px 24px 0 rgba(32, 32, 36, 0.06);
          background-color: #fff;
          .btnSec {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.firstTab {
              justify-content: right;
            }
          }
        }
      }
    }
    .customDataTbl {
      padding: 0px 15px;
      position: relative;
      .scrollbar-container {
        max-height: calc(100vh - 120px);
      }
    }
    .screenSpaceLR {
      padding: 0px 15px;
    }
  }
  .FormWrapper {
    .scannerIcon {
      display: flex;
      align-items: start;
      margin-bottom: 1rem;
      .uploadFileBtn {
        padding-top: 8px;
      }
      .mb-3 {
        width: 90%;
        margin-bottom: 0 !important;
      }
    }
  }
}
.alias_docUpload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .uploadFileBtn label {
    background: #2c88d1;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
    svg {
      color: #ffffff;
      font-size: 17px;
    }
  }
  .uploadFile {
    display: none;
  }
}
.customClose.modal-header {
  position: absolute;
  border-bottom: 0;
  padding: 0 !important;
  background: #fff;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  text-align: center;
  top: -20px;
  right: -15px;
  button {
    margin: 0 auto;
    font-size: 18px;
  }
}
// #page-content-wrapper {
//   .container-fluid{
//     padding: 0;
//   }
// }
.selectedSec {
  display: flex;
  align-items: center;
  padding: 10px 0px 0px 10px;
  background: #fffffe;
  .selectedData {
    display: flex;
    align-items: center;
    gap: 8px;
    label {
      font-size: 14px;
      font-weight: 300;
      margin-top: -14px;
    }
    p {
      font-size: 16px;
      font-weight: normal;
      word-break: break-word;
    }
  }
}
.minMaxSec {
  justify-content: center;
  display: flex;
  gap: 15px;
  font-size: 14px;
  // margin-top: -32px;
  .contentData {
    justify-content: center;
    display: flex;
    gap: 2px;
    p {
      margin: 0;
      font-weight: 500;
    }
  }
}
.selectedSec.formDtls {
  background-color: #e8f2fa;
  border-radius: 10px;
  padding: 15px 10px 0px 10px;
  margin-top: -20px;
  margin-bottom: 10px;
  // .selectedData {
  //   line-height: 0.8;
  // }
  &.mobView {
    display: block;
    margin-top: 0px;
  }
}
