.safetyMedia {
    // display: flex;
    // align-items: center;
    .safetyImgSec {
        border-radius: 6px;
        background-color: #d8d8d8;
        overflow: hidden;
        height: 105px;
        margin-bottom: 20px;
        text-align: center;
        cursor: pointer;
        box-shadow: 0px 0 3px 0 rgba(32, 32, 36, 0.6);
        img {
            max-height: 100px;
        }
    }
}
.safetyModal {
    img {
        min-height: 400px;
    }
}

.videoSec {
    position: relative;
    .video-responsive {
        iframe {
            border-radius: 6px;
            background-color: #d8d8d8;
            overflow: hidden;
            height: 105px;
            margin-bottom: 10px;
        }
    }
    .svgSec {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 20px;
        text-align: center;
        width: 100%;
        cursor: pointer;
        svg {
            color: #fff;
            font-size: 52px;
        }
    }
}
.modal-body {
    position: relative;
    .closeIcon {
        position: absolute;
        right: -20px;
        color: #fff;
        font-size: 40px;
        top: -25px;
        cursor: pointer;
        background: #484848;
        height: 40px;
        display: flex;
        border-radius: 100%;
    }
    .popupImgVideo {
        .video-responsive {
            iframe {
                width: 100%;
                min-height: 400px;
                margin: 0;
                padding: 0;
                border-radius: 6px;
                display: block;
            }
        }
    }
}
