@media screen and (max-width: 575px) {
  .remove {
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 15px;
    display: inline-block;
    margin-left: 10px;
  }

  .customDataTbl .contentHeading .rightSideContent .filter #filter-collapse .multi-select {
    max-width: 275px;
  }
}

@media screen and (max-width: 767px) {
  ::-webkit-scrollbar {
    height: 6px;

    .btnSec {
      button {
        flex: 1 0;
        gap: 1px;
      }
    }
  }

  .mobBtn {
    display: flex;
    position: absolute;
    left: 0;
    bottom: -100px;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  .error_box {
    width: 100%;
  }

  .contentHeading {
    .rightSideContent {
      margin-left: 0 !important;

      .filter #filter-collapse {
        min-width: 300px !important;
      }

      .tblSearch {
        input {
          min-height: 30px;
          background-size: 16px;
          padding-left: 30px;
        }
      }

      .btn {
        font-size: 13px;
      }
    }
  }

  .container-fluid {
    padding: 0 15px;
  }

  .selectionWrapper {
    // margin: 45px 0;
    // padding: 32px 15px 43px 15px;
    margin: 0px;
    padding: 0;
    box-shadow: none;
    background-color: transparent;

    .selectionItem li {
      width: 100%;

      .selectionItemContent {
        min-width: 240px;
      }
    }
  }

  .contentWrapper {
    padding-top: 80px !important;
  }

  .creationWrapper {
    .creationCard {
      // padding: 32px 15px 40px 15px;
      padding: 0;
      background: transparent;
      box-shadow: none;

      .formStepperWrapper {
        width: 100%;
      }

      .btnSec {

        // flex-wrap: wrap;
        button.btn {
          min-width: 110px !important;
        }
      }
    }
  }

  .contentWrapper {
    .contentHeading {
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-bottom: 20px;

      .tabSection {
        width: 100%;
        margin: 20px 0;
      }
    }
  }

  .modal {
    .modal-header {
      padding: 16px;
    }

    .modal-title {
      font-size: 20px;
    }

    .modal-body {
      .preview {
        padding: 16px 16px;
      }
    }

    .p-5 {
      padding: 0 1rem 3rem 1rem !important;
    }
  }

  .customDataTbl {
    .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell {
      font-size: 14px;
    }

    .contentHeading .rightSideContent {
      .filter button.btn-secondary {
        min-width: 35px;
        max-height: 35px;

        img {
          width: 16px;
        }

        &:hover,
        &:active {
          img {
            width: 16px;
          }
        }
      }

      .btn {
        &.btn-primary {
          min-width: 106px;
          max-height: 35px;
          padding: 10px 14px;
          margin-right: 5px;
        }

        svg {
          font-size: 16px;
        }
      }

      .filter #filter-collapse {
        right: auto;
        min-width: 350px;

        // transform: translate(-18%, 0%);
        // transform: translate(-50%, 10px);
        &::before {
          right: 5px;
        }
      }
    }
  }

  .pagination-container li {
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .errorMssg {
    font-size: 14px !important;
    bottom: -20px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .selectionWrapper {
    padding: 32px 30px 43px 30px;

    .selectionItem {
      li {
        width: calc(100% / 2.5);

        .selectionItemContent {
          padding: 32px 15px 43px 15px;
          min-width: 200px;
          min-height: 235px;
        }
      }
    }
  }

  .modal-dialog {
    max-width: 760px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .selectionWrapper {
    .selectionItem {
      li .selectionItemContent {
        padding: 32px 15px 43px 15px;
        min-width: 260px;
      }
    }
  }

  .secondaryBtn.btn-primary,
  .customDataTbl .contentHeading button,
  .primaryBtn.btn-primary {
    padding: 12px 20px !important;
  }

  .customDataTbl .contentHeading .rightSideContent .filter {
    button {
      padding: 9px !important;
      height: 45px;
    }

    #filter-collapse {

      input,
      select {
        height: 30px;
      }
    }
  }

  .customDataTbl .rdt_Table .rdt_TableBody {
    .rdt_TableRow {
      padding: 8px 12px 8px 12px;

      .rdt_TableCell {
        font-size: 14px;
      }
    }
  }

  .registerForm {
    img {
      max-width: 280px;
    }
  }
}