// Graphik font family 
@font-face {
    font-family: 'Graphik';
    src: url('../Fonts/Graphik/GraphikExtralight.otf');
	font-weight: 300;
}
@font-face {
    font-family: 'Graphik';
    src: url('../Fonts/Graphik/GraphikLight.otf');
	font-weight: 400;
}
@font-face {
    font-family: 'Graphik';
    src: url('../Fonts/Graphik/GraphikMedium.otf');
	font-weight: 500;
}
@font-face {
    font-family: 'Graphik';
    src: url('../Fonts/Graphik/GraphikRegular.otf');
	font-weight: 600;
}
@font-face {
    font-family: 'Graphik';
    src: url('../Fonts/Graphik/GraphikBold.otf');
	font-weight: 700;
}
@font-face {
    font-family: 'Graphik';
    src: url('../Fonts/Graphik/GraphikBlack.otf');
	font-weight: 800;
}


/* Graphik font family end  */


// GtSectra font family start 
@font-face {
    font-family: 'Gtsectra';
    src: url('../Fonts/Gtsectra/GTSectraFinelack.ttf');
	font-weight: 600;
}
@font-face {
    font-family: 'Gtsectra';
    src: url('../Fonts/Gtsectra/GTSectraFineMedium.ttf');
	font-weight: 700;
}
@font-face {
    font-family: 'Gtsectra';
    src: url('../Fonts/Gtsectra/GTSectraFineBold.ttf ');
	font-weight: 800;
}
@font-face {
    font-family: 'Gtsectra';
    src: url('../Fonts/Gtsectra/GTSectraFineBook.ttf');
	font-weight: 900;
}

/* Graphik font family end  */



// Graphik trial font family start 
@font-face {
    font-family: 'Graphik-Trial';
    src: url('../Fonts/Graphik-Light-Trial.otf');
	font-weight: 300;
}
@font-face {
    font-family: 'Graphik-Trial';
    src: url('../Fonts/Graphik-Regular-Trial.otf');
	font-weight: 400;
}
@font-face {
    font-family: 'Graphik-Trial';
    src: url('../Fonts/Graphik-Medium-Trial.otf');
	font-weight: 500;
}
@font-face {
    font-family: 'Graphik-Trial';
    src: url('../Fonts/Graphik-Semibold-Trial.otf');
	font-weight: 600;
}
@font-face {
    font-family: 'Graphik-Trial';
    src: url('../Fonts/Graphik-Bold-Trial.otf');
	font-weight: 700;
}
@font-face {
    font-family: 'Graphik-Trial';
    src: url('../Fonts/Graphik-Black-Trial.otf');
	font-weight: 800;
}

/* Graphik-Trial font family end  */

/* Helvetica start  */
@font-face {
    font-family: 'Helvetica';
    src: url('../Fonts/helvetica-light.ttf');
	font-weight: 300;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../Fonts/Helvetica.ttf');
	font-weight: 400;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../Fonts/Helvetica-Bold.ttf');
	font-weight: 500;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../Fonts/Helvetica-Oblique.ttf');
	font-weight: 400;
}
/* Helvetica end  */

@font-face {
    font-family: 'Roboto';
    src: url('../Fonts/Roboto-Light.ttf');
	font-weight: 300;
}
@font-face {
    font-family: 'Roboto';
    src: url('../Fonts/Roboto-Regular.ttf');
	font-weight: 400;
}
@font-face {
    font-family: 'Roboto';
    src: url('../Fonts/Roboto-Medium.ttf');
	font-weight: 500;
}
@font-face {
    font-family: 'Roboto';
    src: url('../Fonts/Roboto-Bold.ttf');
	font-weight: 700;
}
@font-face {
    font-family: 'Roboto';
    src: url('../Fonts/Roboto-Black.ttf');
	font-weight: 900;
}

/* Roboto font family end  */