@import "../../Assets/Styles/variables.scss";

.dropdown-content {
  .panel-content {
    .select-panel {
      .search {
        border-bottom: 0;
      }
      .options {
        //padding: 15px 0;
        padding: 10px 0px 10px 0px;
        -ms-overflow-style: thin;
        scrollbar-width: thin;
        scrollbar-color: #b2bfc9 #fff;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #b2bfc9;
          border-radius: 20px;
        }
        li {
          label {
            padding: 0;
            .item-renderer {
              display: flex;
              align-items: center;
              position: relative;
              padding: 3px 5px 9px 20px;
              input {
                width: 25px;
                height: 25px;
                visibility: hidden;
                position: absolute;
              }
              span {
                &:before {
                  content: "";
                  width: 24px;
                  height: 24px;
                  border-radius: 4px;
                  display: inline-block;
                  position: relative;
                  bottom: -6px;
                  margin-right: 15px;
                  border: 1px solid #979797;
                }
              }
            }
            &.selected {
              background: transparent;
              span {
                position: relative;
                color: $primaryColor;
                &:before {
                  content: "";
                  width: 24px;
                  height: 24px;
                  border-radius: 4px;
                  display: inline-block;
                  position: relative;
                  bottom: -6px;
                  margin-right: 15px;
                  background: url(../../Assets/Images/check.svg) no-repeat;
                  background-position: center;
                  background-color: $primaryColor;
                  border: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
.customMulti {
  .options {
    max-height: 230px;
  }
}
.rmsc.multiSelectWth {
  width: 250px;
}

.rmsc.multiSelectWthDashboard {
  width: 335px;
}