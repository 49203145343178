// variable scss start
@import "../../Assets/Styles/variables.scss";
// variable scss end

// Login page scss start
.loginScreen {
    .loginHeader {
        @extend .displayFCproperty;
        padding: 12px 0;
        img {
            max-width: 180px;
        }
        button {
            background-color: transparent !important;
        }
    }
    .loginContent {
        // margin: 20px 0;
        margin-top: 10px;
        background-color: #fff;
        border-radius: 17px;
        overflow: hidden;
        .loginLeftSec {
            overflow: hidden;
            .loginImg {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                img {
                    max-height: calc(100vh - 110px);
                }
            }
        }
        .loginFormSec {
            .loginIntroContent {
                padding: 24px 64px 24px 64px;
                border-bottom: solid 1px rgba(182, 182, 182, 0.41);
                .loginIntro {
                    .loginFormSubHead {
                        font-family: $font-Gtsectra;
                        font-size: 32px;
                        font-weight: 800;
                        margin-bottom: 0;
                    }

                    .radioSec {
                        .customRadio {
                            width: 75%;
                        }
                    }
                    label {
                        font-family: $font-graphik;
                        font-weight: 500;
                    }
                }
            }
            .loginForms {
                width: 65%;
                margin: 0 auto;
                label {
                    font-size: 14px;
                    margin-bottom: 5px;
                }
                input {
                    height: 40px;
                    margin-bottom: 15px;
                }
                .forgotPassword {
                    font-size: 14px;
                    color: $primaryColor;
                    text-align: right;
                    a {
                        text-decoration: none;
                    }
                }

                .formSigninNew {
                    .form-group {
                        position: relative;
                        .errorMssg {
                            position: absolute;
                            bottom: -15px;
                            font-size: 10px;
                            color: #b6111f;
                            img {
                                width: 12px;
                            }
                        }
                    }
                }
                .loginIntro {
                    margin-bottom: 30px;
                    .loginIntroTxt {
                        font-size: 52px;
                        font-family: $font-Gtsectra;
                        font-weight: 800;
                        margin-bottom: 0;
                        line-height: 52px;
                    }
                }
            }
        }
    }
}
.loginBtnSec {
    width: 100%;
    margin: 30px auto 0;
    .loginBtn {
        border-radius: 8px;
        background-color: #8897a2;
        color: #fff;
        width: 100%;
        padding: 10px;
        border: 0;
        &:hover,
        &:active,
        &:focus {
            background-color: $primaryColor;
            color: #fff;
        }
    }
}
// Login page scss end

@media screen and (max-width: 767px) {
    .loginScreen {
        .loginHeader {
            justify-content: center;
            margin-top: 40px;
        }
        .loginContent {
            background: transparent;
            .loginLeftSec {
                display: none;
            }
            .loginFormSec {
                min-height: calc(100vh - 140px);
                .loginForms {
                    padding: 50px 0 0;
                    width: 100%;
                    .loginIntro {
                        display: flex;
                        margin-bottom: 0;
                        .loginIntroTxt {
                            font-size: 24px;
                        }
                    }
                    .loginBtnSec {
                        width: 90%;
                        margin: 60px auto 0;
                        bottom: 30px;
                        position: absolute;
                        left: 0;
                        right: 0;
                        // width: 100%;
                        // bottom: 30px;
                        // position: unset;
                        // left: 0;
                        // right: 0;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .loginScreen .loginContent .loginFormSec .loginForms {
        width: 85%;
        h5.loginIntroTxt {
            font-size: 42px !important;
            line-height: 42px !important;
        }
    }
}

@media screen and (max-height: 580px) {
    .loginScreen .loginContent .loginFormSec .loginForms .loginBtnSec {
        position: relative;
        bottom: 0;
        width: 100%;
    }
}
